@import "./variable";
@import "./mixin";

.form_field {
  margin-bottom: 25px;
}
.mb50{
  margin-bottom: 50px !important;
}
.text_field, .relative{
  position: relative;
}
.id_verification_label {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: -15px;
  color: var(--white);
  display: block;
  // line-height: 1.5;
}
.relate {
  position: relative;
  .title, .xs_sub_title{
    padding-left: 40px;
  }
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.form_label {
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--greyish-brown);
  display: block;
}
.show_label {
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--greyish-brown);
  display: block;
  line-height: 1.5;
}
.error_msg {
  color: red;
  font-size: 12px;
  &.m-t{
    margin-top: 20px;
    display: block;
  }
}

.list_box {
  border-radius: 12px;
}
.cdk-overlay-pane {
  .list_box & {
    width: 100% !important;
  }
}

input,
textarea {
  @include border-radius(3px);
  padding: 14px 18px;
  width: 100%;
  color: var(--blue);
  background-color: var(--white);
  border: solid 1px var(--warm-gray);
  font: 14px 'Neue Haas Grotesk Text Pro Roman';
  letter-spacing: 0.5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: all 0.30s ease-in-out;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  &:hover,
  &:focus {
    border: solid 1px var(--blue) !important;
    box-shadow: 0 0 5px var(--blue);
  }
  &:read-only, &:read-only {
    // border: none;
    // padding: 0;
    pointer-events: none;
  }
  &::placeholder{
    font-family: 'Inter';
  }
}


.select_input{
  @include border-radius(3px);
  padding: 14px 18px;
  width: 100%;
  color: var(--blue);
  background-color: var(--white);
  border: solid 1px var(--warm-gray);
  font-size: 14px;
  letter-spacing: 0.5px;
  option{
    height: 100%;
    color: var(--blue);
    background-color: #dfe5ef;
    font-size: 14px;
    padding: 10px;
  }
}
.sm_box{
  width: 158px;
}
textarea {
  min-height: 120px;
  resize: none;
  font-family: 'Neue Haas Grotesk Text Pro Roman';
  @media(max-width:1024px){
    min-height: 225px;
    width: 100%;
  }
  &:read-only, &:read-only{
    min-height: 60px
  }
}
.otp_input_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 330px;
  margin: 0 auto 12px 0;
  .sms-no-box {
    width: 48px;
    height: 48px;
    padding: 6px;
    text-align: center;
    display: inline-block;
    margin-right: 20px;
    &:last-child {
      margin: 0;
    }
    @media (max-width: 650px) {
      width: 45px;
      height: 45px;
      margin-right: 24px;
    }
  }
}
.email_change {
  .otp_input_wrapper {
    .sms-no-box {
      @media (max-width: 650px) {
        margin-right: 5px;
      }
    }
  }
}

.fill_btn,
a.fill_btn {
  @include border-radius(3px);
  background-color: var(--yellow);
  font: 14px/1.43 'Neue Haas Grotesk Text Pro Medium';
  letter-spacing: 0.3px;
  color: var(--blue);
  padding: 19px 20px;
  transition: all ease-in 0.3s;
  display: inline-block;
  &:hover {
    background-color: #ecd501;
  }
  @media (max-width: 650px) {
    font-size: 12px;
    padding: 12px 18px;
  }
  &.disable_btn {
    background-color: #fffacb;
    color: #99a4b6;
    pointer-events: none;
  }
}

.fill_grey_btn,
a.fill_grey_btn {
  @include border-radius(3px);
  background-color: var(--grey);
  font: 14px/1.43 'Neue Haas Grotesk Text Pro Medium';
  letter-spacing: 0.3px;
  color: var(--blue);
  padding: 19px 20px;   
  transition: all ease-in 0.3s;
  display: inline-block;
  &:hover {
    background-color: #dadee6;
  }
  @media (max-width: 650px) {
    font-size: 12px;
    padding: 12px 18px;
  }
  &.disable_btn {
    margin-top: 20px;
    background-color: #dfe5ef85;
    color: #99a4b6;
    pointer-events: none;
  }
  &.load_more {
    @include border-radius(8px);
    font-size: 12px;
    padding: 9px 13px;
    margin-bottom: 5px;
  }
}

.sort_btn{
  padding: 14px 20px;
  border-radius: 6px;
  margin-bottom: 16px;
}


.full_btn {
  width: 280px;
  padding: 19px 20px;
  // margin-top: 20px;
  color: var(--blue);
  @media (max-width: 812px) {
    padding: 12px 18px;
  }
  @media (max-width: 650px){
    width: 100%;
  }
}

.choose_btn{
  padding: 12px 15px;
}

.quote_btn{
    width: 100%;
}

.w100per{
  width: 100%;
}

.w85per{
  @media (max-width: 650px) {
    width: 85% !important;
  }
}


.view_all {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 16px;
  @include border-radius(12px);
  border: solid 1px rgba(160, 159, 167, 0.35);
  font-size: 14px;
  line-height: 1.29;
  display: inline-block;
  color: var(--blue);
  @media (max-width: 650px) {
    font-size: 12px;
  }
  &:hover {
    border-color: var(--blue-two);
    color: var(--blue-two);
  }
}

.detect_input {
  padding: 15px 38px 15px 18px;
}
.detect_icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  color: var(--greyish-brown);
  &.eye{
    width: 24px;
    height: 24px;
    background: url('../images/icon-eye-closed.png') center no-repeat;
    background-size: contain;
    &.active{
      background: url('../images/icon-eye-open.png') center no-repeat;
      background-size: contain;
    }
  }

}

.detect_input_ltr {
  padding: 15px 18px 15px 38px;
}
.detect_icon_ltr {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--blue);
  line-height: 1.6;
  img {
    width: 17px;
    height: 17px;
    object-fit: contain;
  }
  &.dollar{
    left: 18px;
    color: var(--greyish-brown);
  }
}


.timer{
  position: relative;
  padding-left: 25px;
  display: inline-block;
  &::before {
      width: 15px;
      height: 15px;
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url('../images/clock.svg') center no-repeat;
      background-size: contain;
    }
}

//--------------Input Check/Radio Box--------------//

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type="checkbox"] + label {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 18px;
  font-size: 14px;
  color: #282828;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

[type="checkbox"].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  transform-origin: 100% 100%;
}

[type="checkbox"] + label:before,
[type="checkbox"]:not(.filled-in) + label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  @include border-radius(1px);
  margin-top: 2px;
  transition: 0.2s;
}

[type="checkbox"].filled-in:checked + label:before {
  top: 3px;
  left: 3px;
  width: 4px;
  height: 8px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid var(--blue-two);
  border-bottom: 2px solid var(--blue-two);
  -webkit-transform: rotateZ(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

[type="checkbox"].filled-in + label:before,
[type="checkbox"].filled-in + label:after {
  content: "";
  left: 0;
  position: absolute;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  z-index: 1;
}

[type="checkbox"].filled-in:not(:checked) + label:after {
  width: 22px;
  height: 22px;
  background-color: transparent;
  border: 1px solid #d2d1d1;
  top: 0px;
  z-index: 0;
  @include border-radius(3px);
}

[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  z-index: 0;
  @include border-radius(3px);
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type="radio"]:not(:checked) + label,
[type="radio"]:checked + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 24px;
  font-size: 14px;
  transition: 0.28s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #363636;
}

[type="radio"] + label:before,
[type="radio"] + label:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  transition: 0.28s ease;
}

[type="radio"]:checked + label:before {
  border: 2px solid transparent;
}

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after {
  @include border-radius(50%);
}

[type="radio"]:checked + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after {
  border: 2px solid #7da527;
}

[type="radio"]:checked + label:after {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

[type="radio"]:checked + label:after {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

[type="radio"]:checked + label:after,
[type="radio"]:checked + label:after {
  background-color: var(--blue-two);
}

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
  border: 1px solid #c1c1c1;
}

[type="radio"]:checked + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:checked + label:after {
  border: 1px solid #c1c1c1;
}
.radio_wrap {
  margin-bottom: 16px;
  label {
    font-size: 16px !important;
    padding-left: 42px !important;
    color: var(--blue) !important;
    line-height: 35px !important;
    height: 30px !important;
  }
  [type="radio"] + label:before,
  [type="radio"] + label:after {
    width: 22px;
    height: 22px;
  }
}
.checkbox_wrap {
  label {
    font-size: 14px;
    line-height: 1.5;
    color: var(--greyish-brown);
    margin-right: 5px;
    a {
      font-family: 'Neue Haas Grotesk Text Pro Medium';
      color: var(--blue);
    }
  }
  .and {
    display: inline-block;
  }
  .m-l-30 {
    display: inline-block;
    @media (max-width: 768px) {
      margin-left: 0px;
    }
  }
}
.pt-10 {
  padding-top: 10px;
}
.pt-30 {
  padding-top: 30px;
  height: 100%;
}
.pr-20{
  padding-right: 20px;
}
.mtb-15 {
  margin: 15px 0px;
}
.mt-15 {
  margin: 15px 0px 0px;
}
.mb0{
  margin-bottom: 0px !important;
}
.mb130{
  margin-bottom: 130px;
  @media(max-width: 768px){
    margin-bottom: 50px;
  }
}

.m-l-30 {
  margin-left: 30px;
}

.m-l-20{
  margin-left: 20px;
  @media(max-width: 650px){
    margin-left: 10px;
  }
}

.m-t-vh{
  margin-top: 20vh;
  @media (max-width: 1366px) {
   margin-top: 15vh;
  }
  @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
   margin-top: 15vh;
  }
}

.pt-20{
  padding-top: 20px;
  .back{
    top: -30px !important;
  }
  .dot_menu {
    right: 55px;
    top: 6px;
    @media(max-width: 768px){
      top: -40px;
    }
    @media(max-width: 650px){
      top: -34px;
    }
  }
}

.m-tb40{
  margin: 40px 0px;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    margin: 15px 0px 30px;
  }
  @media (max-width: 768px){
    margin: 25px 0px;
  }
}

.agree_check {
  [type="checkbox"].filled-in:not(:checked) + label:after {
    @include border-radius(50%);
  }
  [type="checkbox"].filled-in:checked + label:after {
    background-color: var(--blue-two);
    @include border-radius(50%);
  }
  [type="checkbox"].filled-in:checked + label:before {
    border-right: 2px solid var(--yellow);
    border-bottom: 2px solid var(--yellow);
  }
}
//--------------------Input Check/Radio Box Close--------------------//

.btn-disable {
  background: #f9c187;
  cursor: no-drop;

  &:hover {
    background: #f9c187;
  }
}

.uploab-btn {
  position: absolute;
  right: -15px;
  bottom: -15px;
}

.cc_code {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  width: 60px;
}

.bottom_btn.custom_btn{
  position: absolute;
  bottom: 40px;
  @media (max-width: 1366px){
    bottom: 25px;
  }
  &.center{
    left: 50%;
    transform: translateX(-50%);
  }
}

.pic_wrap {
  img {
    @include border-radius(20px);
  }
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: #0000004f;
  position: fixed;
  left: 0;
  z-index: 99;
  display: none;
  &.active {
    display: block;
  }
  &.price_range_active {
    display: block;
    opacity: 0;
    z-index: 1;
  }
}


//------------Upload Media-----------------//
.upload_img_video{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
  .media_btn{
    background-color: transparent;
  }
  .upload_media{
    cursor: pointer;
    width: 100px;
    height: 100px;
    img{
      width: 100px;
      height: 100px;
    }
    @media(max-width: 650px){
      width: 90px;
      height: 90px;
    }
  }
  .img_video{
      width: 100px;
      height: 100px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 32px;
      position: relative;
      @media(max-width: 650px){
        width: 90px;
        height: 90px;
        margin: 0px 15px 15px 0px;
      }
      img, video{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 3px;
      }
      .remove{
          width: 24px;
          height: 24px;
          position: absolute;
          right: -8px;
          top: -10px;
          border-radius: 50%;
          cursor: pointer;
          @media(max-width: 650px){
            width: 18px;
            height: 18px;
          }
      }
  }
}

.upload_profile_pic{
  position: relative;
  .user_img{
    width: 240px;
    height: 240px;
    border-radius: 3px;
    position: relative;
    border: 0.5px solid #f7f8fa;
    @media(max-width: 650px){
      width: 100%;
      margin: 10px 0 15px;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 160px;
      height: 160px;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }
  }
  .camera{
    position: absolute;
    bottom: 10px;
    right: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}
//------------Upload Media close-----------------//


//------------Progress bar---------------//
@keyframes progres{
  0%{
    width: 0%;
  }
  100%{
      width: 100%;
  }
};
.progress_bar {
  position: relative;
  width: 100%;
  position: relative;
  width: 100%;
  background-color: #dfe5ef;
  display: block;
  height: 8px;
  border-radius: 10px;
  margin: 10px 0;
  .done_progress {
      // display: block;
      height: 8px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      position: relative;
      border: none !important;
      padding: 0px !important;
      top: -100%;
      left: 0;
      animation: progres 2s normal;
  }
  .ongoing {
      background-color: var(--blue);
  }
  input[type="range"] { 
      margin: auto;
      -webkit-appearance: none;
      -moz-appearance: none;
      position: relative;
      overflow: hidden;
      pointer-events: none;
      outline: none;
      height: 8px;
  }
  ::-webkit-slider-runnable-track {
      background: #dfe5ef;
  }
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0px;
    height: 40px;
    background: #0b41a8;
    box-shadow: -100vw 0 0 100vw #0b41a8;
    border: 2px #0b41a8 #dfe5ef;
}
  ::-moz-range-track {
      height: 40px;
      background: #dfe5ef;
  }
  ::-moz-range-thumb {
    -moz-appearance: none;
      background: transparent;
      height: 40px;
      width: 0px;
      border-radius: 0 !important;
      border: 0.1em solid #dfe5ef;
      box-shadow: -100vw 0 0 100vw #0b41a8;
      box-sizing: border-box;
  }
}
.completed-digit{
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #313d48;
  font-size: 14px;
  b{
      font-family: 'Neue Haas Grotesk Text Pro Medium';
      font-weight: 500;
  }
}
//------------Progress bar close---------------//


hr {
  height: 1px;
  margin: 32px 0;
  background-color: #dfe5ef;
  @media(max-width: 768px){
    margin: 25px 0;
  }
}


.col_ruler{
  position: relative;
  padding-left: 50px !important;
  @media (max-width: 650px){
    padding: 0 !important;
  }
  &::before{
    content: '';
    position: absolute;
    top: -50px;
    left: 15px;
    width: 1px;
    height: 100vh;
    background-color: #dfe5ef;
    @media (max-width: 650px){
      display: none;
    }
  }
}


// -------------Toggle button----------------//
.toggle_btn {
  .MuiSwitch-root {
    position: relative;
    width: 56px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 25px;
    padding: 0;
    .MuiSwitch-thumb {
      width: 30px;
      height: 30px;
      margin: -8px -6px;
      background-color: white;
    }
    .mat-slide-toggle-persistent-ripple {
      width: 30px;
      height: 30px;
    }
  }
  .mat-slide-toggle.mat-checked .MuiSwitch-thumb {
    background-color: var(--white);
    margin: 4px 0 0 7px;
  }
  .mat-slide-toggle .mat-slide-toggle-ripple {
    position: absolute;
    height: 30px;
    width: 30px;
    z-index: 1;
    pointer-events: none;
    top: 4px;
    left: 0;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: var(--blue-two);
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: var(--blue-two);
  }
  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track{
    opacity: 1;
  }
}
// -------------Toggle button close----------------//