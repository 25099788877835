/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

@import "./reset";
@import "./variable";
@import "./form";
@import "./layout";
@import "./grid";
@import "./mixin";
@import "./effects";

@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

// -------------------Fonts-----------------------//
// @font-face {
//   font-family: "Neue Haas Grotesk Text Pro Roman";
//   font-style: normal;
//   font-weight: normal;
//   src: local("Neue Haas Grotesk Text Pro Roman"),
//     url("../fonts/NHaasGroteskTXPro-55Rg.woff") format("woff");
// }

@font-face {
  font-family: "Neue Haas Grotesk Text Pro Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Neue Haas Grotesk Text Pro Medium"),
    url("../fonts/NHaasGroteskTXPro-65Md.woff") format("woff");
}

@font-face {
  font-family: "Neue Haas Grotesk Text Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Neue Haas Grotesk Text Pro Bold"),
    url("../fonts/NHaasGroteskTXPro-75Bd.woff") format("woff");
}

@font-face {
  font-family: "Neue Haas Grotesk Text Pro Roman";
  font-style: normal;
  font-weight: normal;
  src: local("Neue Haas Grotesk Text Pro Roman"),
    url("../fonts/Neue Haas Grotesk Text Pro 55 Roman.woff") format("woff"); //roman
}

@font-face {
  font-family: "Neue Haas Grotesk Text Pro Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Neue Haas Grotesk Text Pro Medium"),
    url("../fonts/NHaasGroteskTXPro-65Md.woff") format("woff");
}

// -------------------Fonts close-----------------------//
body {
  font-family: "Inter", sans-serif !important;
}

.ft_normal {
  font-family: "Inter";
  font-weight: normal;
}
.ft_bold {
  font-family: "Neue Haas Grotesk Text Pro Medium";
  font-size: 16px !important;
  color: var(--blue) !important;
}

.arrow_icon {
  right: 10px;
  top: 10px;
  position: absolute;
}

.app_wrapper {
  position: relative;
  height: 100%;
  padding: 80px 0px 0px;
  width: 100%;
  max-width: 1640px;
  margin: 0 auto;
  &.home {
    padding: 0px 0px 180px;
  }
}

.custom_container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100%;
  @media (min-width: 991px) {
    width: calc(100% - 160px);
  }
}

.section_wrapper {
  padding: 40px 0 0 0;
  position: relative;
  @media (max-width: 650px) {
    padding: 20px 0 0 0;
  }
  &.top_wrap {
    padding: 60px 0 0 0;
  }
}

.mt-sm {
  margin-top: 15px;
}

.commn_para {
  font-size: 14px;
  line-height: 1.43;
  color: var(--greyish-brown);
  margin: 0 0 15px 0;
  @media (max-width: 650px) {
    font-size: 12px;
  }
}

.info_note {
  font-size: 14px;
  text-align: left;
  color: var(--greyish-brown);
  display: inline-block;
  margin-bottom: 20px;
}

.title {
  font: 32px/1.16 "Neue Haas Grotesk Text Pro Medium";
  color: var(--blue);
  margin-bottom: 32px;
  display: block;
  @media (max-width: 812px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    font-size: 26px;
    margin-bottom: 14px;
    max-width: 85%;
  }
  @media (max-width: 650px) {
    font-size: 18px;
    max-width: 100%;
    margin-bottom: 15px;
  }
}
.mr-t-50 {
  margin-top: 65px;
}
.sub_title {
  font: 24px/1.33 "Neue Haas Grotesk Text Pro Medium";
  color: var(--blue);
  letter-spacing: 0.1px;
  margin-bottom: 16px;
  display: block;
  position: relative;
  @media (max-width: 812px) {
    font-size: 22px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 650px) {
    font-size: 18px;
  }
}

.xs_sub_title {
  font: 18px/1.33 "Neue Haas Grotesk Text Pro Medium";
  color: var(--blue);
  letter-spacing: 0.1px;
  margin-bottom: 16px;
  display: block;
  @media (max-width: 812px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 650px) {
    font-size: 14px;
  }
}

.inner_title {
  font: 16px/1.33 "Neue Haas Grotesk Text Pro Medium";
  color: var(--blue);
  letter-spacing: 0.1px;
  margin-bottom: 15px;
  display: block;
  @media (max-width: 812px) {
    font-size: 14px;
  }
}

.xs_head {
  font: 14px "Neue Haas Grotesk Text Pro Medium";
  color: var(--blue);
  margin-bottom: 10px;
  display: block;
}

.tagg {
  font-size: 15px;
  line-height: 1.13;
  color: var(--dark-grey);
  display: block;
  margin-bottom: 15px;
  &.mb30 {
    margin-bottom: 30px;
  }
}

.edit_icon {
  position: absolute;
  right: auto;
  margin-left: 10px;
  top: 0px !important;
  cursor: pointer;
  img {
    width: 24px !important;
    height: 24px !important;
    @media (max-width: 650px) {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.icon {
  position: relative;
  font-size: 14px;
  color: var(--greyish-brown);
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 12px;
  }
  &:before {
    content: "";
    @include position(absolute, 50%, auto, auto, 0);
    transform: translateY(-50%);
  }
}

//------------------------Tags------------------//

.custcardbord {
  border-radius: 12px;
  padding: 15px 10px;
  width: 100%;
  color: var(--black-four);
  background-color: var(--white);
  border: solid 1px #dfe5ef !important;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease-in-out;
}

.custcardbord.StripeElement.StripeElement--empty iframe {
  height: 19.2px !important;
}

.show_more {
  margin: 0 0 0 10px;
}
.tags_wrap {
  &.form_wrapper {
    width: 100%;
    max-width: 655px;
    @media (max-width: 1366px) {
      max-width: 575px;
    }
    @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      max-width: 575px;
    }
    .fill_btn {
      max-width: 327px;
      width: 100%;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 40px;
    &.more_tags {
      max-height: 80px;
      overflow: hidden;
      margin: 0 0 8px;
      &.active {
        height: auto;
      }
    }
    li {
      display: flex;
      align-items: center;
      margin: 0 12px 12px 0;
      padding: 8px;
      @include border-radius(2px);
      background-color: #dfe4ed;
      font: 12px/1.3 "Neue Haas Grotesk Text Pro Roman";
      color: var(--blue);
      cursor: pointer;
      &.active {
        background-color: var(--blue-two);
        color: var(--white);
      }
      &.no_data {
        background-color: transparent;
        margin: 0;
        padding: 0;
        height: 90px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &.selected {
        background-color: var(--blue-two);
        color: var(--white);
      }
      @media (max-width: 812px) {
        margin: 0 10px 10px 0;
        padding: 5px;
        font-size: 11px;
      }
      &.main {
        background-color: var(--blue-two);
        color: var(--white);
        img {
          width: 15px;
          height: 15px;
          margin-right: 4px;
          object-fit: contain;
        }
      }
    }
  }
}

.tag_status {
  padding: 4px 8px;
  @include border-radius(4px);
  font: 10px/1.3 "Neue Haas Grotesk Text Pro Medium";
  letter-spacing: 0.5px;
  background-color: var(--light-gray);
  color: var(--greyish-brown);
  text-transform: uppercase;
  display: inline-block;
  @media (max-width: 812px) {
    font-size: 9px;
  }
  &.verified,
  &.approved {
    background-color: rgba(51, 164, 81, 0.1);
    color: #33a451;
  }
  &.active {
    background-color: rgba(252, 110, 32, 0.1);
    color: var(--blue-two);
  }
  &.nverified,
  &.inactive {
    background-color: #c4000059;
    color: #df1919;
  }
}

//------------------------Tags close------------------//

.hide {
  display: none;
  @media (max-width: 812px) {
    display: block;
  }
}

.none {
  display: none;
}

.mb_wrap {
  @media (max-width: 812px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .reg {
    position: relative;
    color: #fff;
    // @media (max-width: 812px) {
    //   font-size: 13px;
    //   font-weight: 500;
    // }
    // @media (max-width: 320px) {
    //   font-size: 11px;
    // }
  }
}

.mob_logo {
  max-width: 200px;
  margin: 215px auto 0;
  text-align: center;
  position: relative;
  @media (max-width: 1368px) {
    margin-top: 50px;
  }
}
.left_logo {
  position: absolute;
  left: 4%;
  top: 102px;
  margin: 0;
}
// preview
.file_upload_box {
  border: 1px solid var(--warm-gray);
  padding: 4px 7px;
  width: 100px;
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  z-index: 99;
  .close {
    @include position(absolute, -8px, -8px, auto, auto);
    cursor: pointer;
    img {
      width: 18px;
    }
  }
  .file_icon {
    width: 32px;
    height: 35px;
    display: inline-block;
    margin: 0 8px 0 0;
  }
  .file_details {
    width: 73%;
  }
  .name {
    width: 80%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    text-overflow: ellipsis;
    color: var(--blue);
  }
}

//-----------------Onboard form----------------//
.form_wrapper {
  padding: 100px 0 30px;
  width: 327px;
  margin: 0 auto;
  max-width: 327px;
  @media (max-width: 1150px) {
    padding: 70px 30px 40px;
    max-width: 427px;
    margin: 0 auto;
    width: 100%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    width: 427px;
  }
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    width: 400px;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    width: 400px;
  }
  .fill_btn,
  .fill_grey_btn {
    width: 100%;
    text-align: center;
    @media (max-width: 768px) {
      &.verified {
        &::after {
          right: -40px;
        }
      }
    }
  }
  h1 {
    color: var(--white);
    margin-bottom: 15;
    @media (max-width: 768px) {
      font-size: 28px;
      margin-bottom: 20px;
    }
    @media (max-width: 650px) {
      font-size: 24px;
      text-align: center;
    }
  }
  &.landing_form_wrapper {
    padding-top: 0;
  }
}

.continue_with {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 24px 0;
  a {
    @include border-radius(3px);
    background-color: var(--warm-gray);
    width: 103px;
    padding: 10px;
    text-align: center;
    @media (max-width: 415px) {
      width: 100px;
    }
    @media (max-width: 320px) {
      width: 110px;
    }
    &.fb {
      background-color: #3a559f;
    }
    img {
      width: 25px;
      height: 24px;
      @media (max-width: 375px) {
        width: 20px;
        height: 20px;
      }
    }
  }
}

//-----------------Onboard form close----------------//
//--------------------Icons------------------------//
.back {
  background: url("../images/icon-direction-left.png") center no-repeat;
  background-size: contain;
  @include position(absolute, 50%, auto, auto, 0px);
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  &.bk_white {
    background: url("../images/icon-back-white.png") center no-repeat;
  }
}
.share {
  font: 14px/1.14 "Neue Haas Grotesk Text Pro Medium";
  letter-spacing: 0.1px;
  color: var(--greyish-brown);
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  @media (max-width: 812px) {
    padding-left: 20px;
  }
  &::before {
    @include position(absolute, 50%, auto, auto, 0px);
    transform: translateY(-50%);
    content: "";
    width: 24px;
    height: 24px;
    background: url("../images/ic-share.png") center no-repeat;
    background-size: contain;
    @media (max-width: 812px) {
      width: 18px;
      height: 18px;
    }
  }
}

.rating {
  font-size: 13px;
  line-height: 1.43;
  display: block;
  color: var(--greyish-brown);
  padding-left: 25px;
  position: relative;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 16px;
    padding-left: 20px;
  }
  &::before {
    @include position(absolute, 50%, auto, auto, 0px);
    transform: translateY(-50%);
    content: "";
    width: 16px;
    height: 16px;
    background: url("../images/star.svg") center no-repeat;
    background-size: contain;
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 13px;
      height: 13px;
    }
  }
}

.rating_reach {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  display: block;
  margin-bottom: 5px;
  color: var(--greyish-brown);
  padding-left: 20px;
  position: relative;
  @media (max-width: 812px) {
    font-size: 13px;
    padding-left: 18px;
  }
  &::before {
    @include position(absolute, 50%, auto, auto, 0px);
    transform: translateY(-50%);
    content: "";
    width: 12px;
    height: 12px;
    background: url("../images/ic_rating_star.png") center no-repeat;
    background-size: contain;
    @media (max-width: 812px) {
      width: 10px;
      height: 10px;
    }
  }
  .dot {
    position: relative;
    padding-left: 10px;
    &::before {
      width: 4px;
      height: 4px;
      content: "";
      @include position(absolute, 50%, auto, auto, 2px);
      transform: translateY(-50%);
      background-color: var(--greyish-brown);
      @include border-radius(50%);
    }
  }
}

.autosuggestion_icon {
  font: 14px "Neue Haas Grotesk Text Pro Medium";
  color: var(--greyish-brown);
  position: relative;
  padding-left: 55px;
  cursor: pointer;
  &::before {
    @include position(absolute, 50%, auto, auto, 0);
    transform: translateY(-50%);
    content: "";
    width: 40px;
    height: 40px;
    background-size: contain;
  }
  &.history {
    &::before {
      background: url("../images/ic-history.png") center no-repeat;
    }
  }
  &.loc {
    &::before {
      background: url("../images/map-pin.png") center no-repeat;
    }
  }
}

.bookmark_icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 9;
  cursor: pointer;
  background: url("../images/save.png") center no-repeat;
  background-size: auto;
  @include position(absolute, 0, auto, auto, 415px);
  &.active {
    background: url("../images/saved.png") center no-repeat;
    background-size: auto;
  }
}

//--------------------Icons close------------------------//
//--------------------Header sub menu------------------------//
.sub_menu {
  .MuiMenu-paper {
    padding: 20px 10px 0 32px;
    width: 262px;
    margin: 0 0 0 20px;
    border-radius: 3px;
    box-shadow: 0 20px 24px 0 rgba(132, 132, 132, 0.2);
    @media (max-width: 650px) {
      padding: 0 10px 0 20px;
      width: 230px;
      margin: 0;
    }
    .sub_title {
      font-size: 20px;
      @media (max-width: 650px) {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
    .MuiMenuItem-root {
      margin: 0 -32px 10px;
      @media (max-width: 650px) {
        margin: 0 -20px 2px;
      }
      &:hover {
        .setting_icon {
          color: var(--blue-two);
          width: 100%;
          &.logout {
            color: #f11820;
            display: block;
          }
        }
      }
    }
  }
  .setting_icon {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.43;
    color: var(--blue);
    padding-left: 18px;
    @media (max-width: 650px) {
      padding-left: 8px;
    }
    img {
      margin-right: 15px;
      width: 24px;
      height: 24px;
    }
    &.active {
      font-family: "Neue Haas Grotesk Text Pro Medium";
    }
    &.logout {
      color: #ed1c24;
    }
  }
  &.notifications {
    .MuiMenu-paper {
      width: 433px;
      .sub_title {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
    .mark_all {
      @include position(absolute, 15px, 15px, auto, auto);
    }

    .MuiListItem-button {
      background-color: #f6f7f9;
      padding-bottom: 10px;
      margin: 0 -32px 0px;
      &:last-child {
        padding-bottom: 0;
      }
      &.unread {
        background-color: var(--white);
        &:hover {
          background-color: #f6f7f9;
        }
        .who {
          font-family: "Neue Haas Grotesk Text Pro Medium";
        }
      }
    }
    .notif {
      width: 92%;
    }
  }
}

.more_notif {
  text-align: center;
  padding: 10px 0;
}

.notif {
  position: relative;
  span {
    font-size: 14px;
    line-height: 1.43;
    color: var(--greyish-brown);
    margin: 2px 0;
    display: block !important;
  }
  .time {
    @include position(absolute, 0, 0, auto, auto);
    color: var(--dark-grey);
    font-size: 12px;
  }
  .not_img {
    width: 64px;
    height: 64px;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    .dot {
      width: 12px;
      height: 12px;
      background-color: var(--yellow);
      border-radius: 50%;
      @include position(absolute, 3px, auto, auto, 0px);
    }
  }
  .see {
    color: #123f95;
    font-family: "Neue Haas Grotesk Text Pro Medium";
    padding: 2px 8px;
    border-radius: 6px;
    background-color: var(--grey);
    display: inline-block !important;
  }
  .info {
    width: 55%;
    display: inline-block;
  }
}

.no_notification {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  height: 330px;
  figure {
    height: 100px;
    img {
      height: 100px;
    }
  }
  span {
    color: #547ac2;
    font-size: 14px;
    display: block;
    margin: 5px 0;
  }
}

//------------------------- empty screens---------------------------//
.empty_screen_text {
  font-size: 20px;
  text-align: center;
  font: 20px/1.33 "Neue Haas Grotesk Text Pro Medium";
  align-items: center;
  display: "inline-block";
  line-height: 20px;
  font-weight: 700;
  color: #161d4a !important;
  margin-top: 12px;
  max-width: 200px;
  letter-spacing: 0.5px;
}

.empty_screen_subtext {
  font: 16px/1.33 "Neue Haas Grotesk Text Pro Medium";
  font-size: 16px;
  align-items: center;
  display: inline-block;
  line-height: 20px;
  // fontWeight: "bold",
  color: #161d4a;
  margin-top: 20px;
  max-width: 300px;
  letter-spacing: 0.5px;
  text-align: center;
}
.notification_subtext {
  font-size: 16px;
  font: 16px/1.33 "Neue Haas Grotesk Text Pro Medium";
  align-items: center;
  display: inline-block;
  line-height: "20px";
  color: #161d4a;
  margin-top: 20px;
}
.empty_screen_button {
  padding: 19px 32px;
  width: 327px;
  border-radius: 3px;
  background-color: #fee600;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #161d4a;
  margin-top: 20px;
}
//--------------------Header sub menu close------------------------//

//------------------------Onboard Model-------------------------//
.onboard_modal {
  padding: 0 !important;
  .close_btn {
    top: 20px !important;
    right: 30px !important;
  }
  .react-multi-carousel-dot-list {
    bottom: 135px;
  }
  .left_col {
    width: 312px;
    max-height: 100vh;
    position: fixed;
    overflow: hidden;
    @media (max-width: 812px) {
      display: none;
    }
    .banner_img {
      @media (max-width: 1500px) {
        height: 550px;
      }
      @media (max-width: 1024px) {
        height: 667px;
      }
    }
    .bottom_txt {
      left: 45%;
      bottom: 5%;
    }
  }
  .right_col {
    padding-left: 312px;
    @media (max-width: 812px) {
      padding-left: 0;
    }
    .form_wrapper {
      margin: 0 32px;
      padding: 0px 0px 30px;
      @media (max-width: 812px) {
        margin: 0 auto;
        padding: 40px 20px;
      }
      &.select_sphere {
        max-width: 530px;
        @media (max-width: 768px) {
          max-width: 550px;
        }
        ul {
          li {
            margin: 0 11px 25px 11px;
          }
        }
      }
      &.tags_wrap {
        max-width: 515px;
      }
    }
  }
  .onboard_wrapper {
    background: var(--white);
    .onboarding_head {
      margin: 40px 0 40px 60px;
      @media (max-width: 768px) {
        margin: 40px auto 30px;
      }

      .back_btn {
        left: -45px;
        background: url("../images/icon-direction-left.png") center no-repeat;
        @media (max-width: 768px) {
          left: 160px;
          z-index: 9;
        }
      }
      h1 {
        color: var(--blue);
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
          text-align: center;
        }
      }
    }
    .custom_steppr {
      li {
        &.active {
          background-color: var(--blue-two);
        }
      }
    }
  }
  .img_text_wrap {
    .full_image {
      height: 100%;
      @media (max-width: 1366px) {
        height: 100%;
      }
    }
  }
}

.bottom_txt {
  @include position(absolute, auto, auto, 25%, 50%);
  transform: translateX(-50%);
  z-index: 9;
  @media (max-width: 1366px) {
    bottom: 10%;
  }
  @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    bottom: 10%;
  }
  // .reg {
  //   font: 14px/1.29 "Neue Haas Grotesk Text Pro Medium";
  //   letter-spacing: 0.1px;
  //   color: var(--white);
  // }
  // .link {
  //   color: var(--yellow);
  //   &:after {
  //     background-color: var(--yellow);
  //   }
  //   &:hover {
  //     color: var(--yellow) !important;
  //   }
  // }
}
.reg {
  position: relative;
  font: 14px/1.29 "Neue Haas Grotesk Text Pro Bold";
  letter-spacing: 0.1px;
  color: var(--white);
  .link {
    color: var(--yellow);
    &:after {
      background-color: var(--yellow);
    }
    &:hover {
      color: var(--yellow) !important;
    }
  }
}
.banner_img {
  position: relative;
  height: 100vh;
  z-index: -1;
  @media (max-width: 1366px) {
    height: 100vh;
  }
  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}
.banner_img_home {
  position: relative;
  z-index: -1;
  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}

.logo {
  @include position(absolute, 10%, auto, auto, 50%);
  transform: translateX(-50%);
}

.slider_txt {
  z-index: 9;
  text-align: center;
  margin-bottom: 70px;
  // @include position(absolute, calc(120px + 80px), auto, auto, 50%);
  // transform: translate(-50%, 50%);
  width: 100%;
  // max-height: 50px;
  span {
    font: 26px/1.25 "Neue Haas Grotesk Text Pro Medium";
    // font-family: 'Inter';
    letter-spacing: 0.1px;
    color: var(--white);
    max-width: 262px;
    margin: auto;
    display: block;
    @media (max-width: 1024px) {
      font-size: 24px;
    }
  }
}

//----------------------Onboard Model close----------------------//

//----------------------Job/Story/Profile Detail-------------------//
.change_req_detail {
  ul {
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey);
      margin-bottom: 10px;
    }
    li {
      margin-bottom: 10px;
    }
  }
  .inner_title {
    margin: 0;
    font: 15px/1.33 "Neue Haas Grotesk Text Pro Roman";
  }
}

.vid_img_wrapper {
  .flex_col_sm_3 {
    @media (max-width: 1024px) {
      width: 33.33%;
    }
    @media (max-width: 812px) {
      width: 50%;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  .flex_col_sm_4 {
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 33%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .job_categories {
    li {
      cursor: auto;
    }
  }
  .tags_wrap {
    ul {
      li {
        cursor: auto;
      }
    }
  }
  .back {
    top: -20px;
    left: 10px;
    cursor: pointer;
    @media (max-width: 650px) {
      top: -20px !important;
    }
  }
  .tags_wrap {
    ul {
      @media (max-width: 768px) {
        margin: 0 0 15px;
      }
    }
  }
  .vid_img_thumb {
    position: relative;
    @include border-radius(3px);
    background-color: #f3f3f3;
    height: 400px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
    img,
    video {
      width: 100%;
      height: 400px;
      object-fit: contain;
      @include border-radius(3px);
      @media (max-width: 650px) {
        width: 100% !important;
      }
    }
    .doc_icon {
      padding-top: 125px;
      padding-bottom: 125px;
    }
    .edit_icon {
      top: 10px !important;
      right: 20px;
      img {
        @media (max-width: 650px) {
          width: 24px !important;
        }
      }
    }
    .owl-nav {
      .nav-btn {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .owl-prev {
        @include position(absolute, auto, 80px, 30px, auto);
        @include border-radius(50%);
        background-color: #938d8b;
        &:hover {
          background-color: var(--blue-two);
        }
        @media (max-width: 650px) {
          @include position(absolute, auto, 80px, 40px, auto);
        }
      }
      .owl-next {
        @include position(absolute, auto, 20px, 30px, auto);
        @include border-radius(50%);
        background-color: #938d8b;
        &:hover {
          background-color: var(--blue-two);
        }
        @media (max-width: 650px) {
          @include position(absolute, auto, 20px, 40px, auto);
        }
      }
      img {
        width: 18px !important;
        height: 18px;
      }
    }
    .owl-dots {
      @include position(absolute, auto, auto, 40px, 30px);
      @media (max-width: 650px) {
        @include position(absolute, auto, auto, 50px, 10px);
      }
      .owl-dot span {
        background: rgba(255, 255, 255, 0.4);
      }
      .owl-dot.active span,
      .owl-dot:hover span {
        background: var(--white);
      }
    }
  }
  .description {
    margin: 45px 0px 20px;
    .sub_title {
      margin-bottom: 18px;
      @media (max-width: 650px) {
        margin-bottom: 12px;
      }
    }
    &.public_view_btn2 {
      margin: 125px 0px 20px;
    }
    p {
      font-size: 14px;
      line-height: 1.56;
      color: var(--greyish-brown);
      margin: 0 0 15px 0;
    }
  }
  .area {
    margin: 45px 0px 20px;
  }
  .detail_card {
    padding: 0 0 0 10px;
    @include position(0, auto, auto, auto);
    width: 95%;
    .form_field {
      margin-bottom: 15px;
      &:last-child {
        margin: 0;
      }
    }
    .full_btn {
      width: 100%;
      @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      position: initial;
      width: 100%;
    }
    .full_btn {
      width: 100%;
      @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        width: 100%;
      }
    }
    .job_info {
      padding: 12px 15px;
      margin: 0;
      ul {
        justify-content: initial;
        li {
          flex: 1 1 50%;
          margin: 13px 0;
          &.job_status {
            font-size: 12px;
            padding-left: 5px;
          }
          &.icon {
            padding-left: 38px;
            @media (max-width: 650px) {
              padding-left: 28px;
            }
            &:before {
              width: 24px;
              height: 24px;
              @media (max-width: 650px) {
                width: 18px;
                height: 18px;
                background-size: contain !important;
              }
            }
            &.clock {
              &::before {
                background: url("../images/clock-1.png") center no-repeat;
              }
            }
            &.dollar {
              &::before {
                background: url("../images/dollar-1.png") center no-repeat;
              }
            }
            &.location {
              line-height: 1.8;
              &::before {
                background: url("../images/location-1.png") center no-repeat;
              }
            }
            &.calendar {
              &::before {
                background: url("../images/calendar-1.png") center no-repeat;
              }
            }
          }
        }
      }
    }
    .rate_action_wrap {
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tag_status {
        margin: 0 0 0 20px;
      }
    }
    .title {
      margin-bottom: 16px;
      position: relative;
      width: 100%;
      @media (max-width: 1024px) {
        font-size: 22px;
      }
      @media (max-width: 812px) {
        font-size: 17px;
      }
      .edit_icon {
        right: 0;
      }
    }
    .sub_title {
      position: relative;
      @media (max-width: 812px) {
        font-size: 15px;
      }
    }
    .bottom_btn {
      display: flex;
      margin-top: 15px;
      @media (max-width: 768px) {
        position: relative;
        margin-top: 20px;
      }
      .full_btn {
        width: 82%;
      }
    }
  }
  &.item_detail {
    .bottom_btn {
      .fill_btn,
      .fill_grey_btn {
        width: 280px;
        @media (max-width: 1150px) {
          width: 200px;
        }
        @media (max-width: 900px) {
          width: 160px;
        }
        @media (max-width: 650px) {
          width: 100%;
        }
      }
    }
  }
  .job_progress_wrap {
    max-width: 345px;
    .approval_info {
      right: auto;
      left: 0;
      top: -22px;
    }
  }
}

.review_job {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  li {
    // width: 180px;
    width: 100%;
    height: 80px;
    padding: 16px;
    background-color: #f7f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    &:not(:last-child) {
      margin-right: 16px;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      padding: 10px;
      margin-right: 10px;
    }
    .icon {
      position: relative;
      padding-left: 35px;
      font: 24px "Neue Haas Grotesk Text Pro Medium";
      color: var(--blue);
      margin-bottom: 8px;
      display: block;
      min-height: 30px;
      &::before {
        width: 24px;
        height: 24px;
      }
      &.reviews {
        &::before {
          background: url("../images/reviews.png") center no-repeat;
          background-size: contain;
        }
      }
      &.job {
        &::before {
          background: url("../images/jobs-complete.png") center no-repeat;
          background-size: contain;
        }
      }
    }
    .review_count {
      font-size: 14px;
      color: var(--greyish-brown);
      line-height: 1;
      margin-top: 10px;
    }
  }
}

.portfolio_wrappr {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .media {
    display: inline-block;
    cursor: pointer;
    .line-3 {
      -webkit-line-clamp: 7;
    }
    .portfolio_img {
      width: 178px;
      height: 178px;
      border-radius: 3px;
      position: relative;
      overflow: hidden;
      @media (max-width: 650px) {
        width: 145px;
        height: 145px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .xs_sub_title {
        @include position(absolute, auto, auto, -100%, 0px);
        margin: 0;
        color: var(--white);
        line-height: 1.3;
        justify-content: center;
        display: flex;
        align-items: flex-end;
        padding: 10px;
        -webkit-transition: all ease 0.6s;
        -o-transition: all ease 0.6s;
        transition: all ease 0.6s;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
      }
      .edit_icon {
        opacity: 0;
        visibility: hidden;
        right: -100%;
        top: 10px !important;
        -webkit-transition: all ease 0.6s;
        -o-transition: all ease 0.6s;
        transition: all ease 0.6s;
        z-index: 9;
      }
      &.active,
      &:hover {
        .xs_sub_title {
          visibility: visible;
          opacity: 1;
          background-color: rgba(22, 29, 74, 0.5);
          bottom: 0;
        }
        .edit_icon {
          visibility: visible;
          opacity: 1;
          right: 10px;
        }
      }
    }
  }
}

.chang_req_card {
  min-height: 140px;
  padding: 14px 10px 14px 14px;
  box-shadow: 0 10px 30px 0 rgba(183, 183, 211, 0.19);
  background-color: var(--white);
  margin-bottom: 16px;
  button {
    width: 140px;
    padding: 15px 10px;
    &:last-child {
      margin-left: 15px;
    }
    @media (max-width: 1024px) {
      width: 110px;
      padding: 12px 10px;
    }
  }
}

//----------------------Item/Profile Detail close---------------------------//

//-------------------Review Rating card-----------------------//
.review_parent {
  .flex_col_sm_3 {
    @media (max-width: 1024px) {
      width: 33.33%;
    }
    @media (max-width: 768px) {
      width: 50%;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 50%;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}

.review_card {
  max-width: 280px;
  min-height: 180px;
  margin: 0 0 30px;
  padding: 16px;
  @include border-radius(3px);
  background-color: #f7f8fa;
  position: relative;
  &:hover {
    box-shadow: 0 0 20px 0 #00000014;
  }
  @media (max-width: 812px) {
    max-width: 100%;
  }
  .pic_shot_dtl {
    display: flex;
    margin-bottom: 14px;
    .u_img {
      width: 40px;
      height: 40px;
      overflow: hidden;
      @include border-radius(5px);
      margin: 0 8px 0 0;
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
    }
    .name_wrap {
      width: 80%;
      .user_name {
        display: block;
        font: 14px/1.43 "Neue Haas Grotesk Text Pro Medium";
        color: var(--blue);
        max-width: 125px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @media (max-width: 1366px) {
          max-width: 110px;
        }
      }
      .date {
        font-size: 12px;
        line-height: 1.67;
        text-align: left;
        color: var(--greyish-brown);
      }
    }
  }
  .commn_para {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .r_tag_wrap {
    display: flex;
    flex-flow: wrap;
    margin-bottom: 16px;
    .tag {
      margin: 0 12px 12px 0;
      padding: 4px 16px;
      @include border-radius(6px);
      background-color: var(--white);
      display: inline-flex;
      font: 12px/1.5 "Neue Haas Grotesk Text Pro Medium";
      text-align: center;
      color: var(--greyish-brown);
    }
  }
  .bottom_note {
    font: 14px/1.29 "Neue Haas Grotesk Text Pro Medium";
    color: var(--blue);
  }
  &.vouchers {
    .commn_para {
      -webkit-line-clamp: 4;
    }
  }
  .vouch {
    margin-top: 15px;
    .vouch_icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 6px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .link {
      font-size: 12px;
    }
  }
}

.add_vouch {
  padding: 16px 18px;
}

.rating_star {
  @include position(absolute, 12px, 10px, auto, auto);
  font-size: 14px;
  .react-stars {
    span {
      margin: 0;
    }
  }
  @media (max-width: 1150px) {
    position: static;
  }
}
//-------------------Review Rating card close-----------------------//

//-------------------Question Answer/ Custom/ Filter model------------------------//
.custom_wh {
  position: relative;
  padding: 30px;
  width: 880px;
  max-width: 880px;
  margin: 80px auto;
  background-color: var(--white);
  height: 666px;
  max-height: 666px;
  overflow-y: auto;
  border-radius: 3px;
  @media (max-width: 1500px) {
    margin: 16px auto 0;
    height: 550px;
    max-height: 550px;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90% !important;
    margin: 30px auto;
    height: 100% !important;
  }
  @media (max-width: 1280px) {
    margin: 20px auto 0;
    height: 460px;
    max-height: 460px;
  }
  @media (max-width: 768px) {
    width: 95% !important;
    height: 100% !important;
    max-height: 90% !important;
  }
  @media (max-width: 650px) {
    padding: 18px 15px;
  }
  textarea {
    min-height: 95px;
  }
  &.ask_ques {
    width: 864px;
    max-width: 864px;
    height: 503px;
    max-height: 503px;
    @media (max-width: 1500px) {
      margin: 16px auto 0;
      height: 490px;
      max-height: 490px;
    }
    @media (max-width: 1280px) {
      margin: 20px auto 0;
      height: 460px;
      max-height: 460px;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 90%;
      margin: 30px auto;
      height: 100%;
    }
    @media (max-width: 768px) {
      width: 95%;
      height: 100%;
      max-height: 85%;
    }
    @media (max-width: 650px) {
      height: 100%;
      max-height: 60%;
      margin: 15px auto;
    }
    textarea {
      min-height: 242px;
      @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        min-height: 130px;
      }
    }
  }
  &.confirmation {
    width: 480px;
    max-width: 480px;
    height: 255px;
    max-height: 255px;
    @media (max-width: 1500px) {
      margin: 80px auto 0;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 90%;
      margin: 30px auto;
      height: 100%;
    }
    @media (max-width: 768px) {
      width: 95%;
      height: 100%;
      max-height: 25%;
    }
    @media (max-width: 650px) {
      height: auto !important;
      margin: 15px auto;
    }
    .modal_message {
      text-align: center;
      max-height: 115px;
      overflow-y: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      p {
        font-size: 16px;
        line-height: 1.5;
        @media (max-width: 650px) {
          font-size: 14px;
        }
      }
    }
    .dialog_actions {
      @include position(absolute, auto, auto, 18px, 50%);
      transform: translateX(-50%);
      .fill_btn,
      .fill_grey_btn {
        padding: 12px 25px;
      }
    }
  }
  &.portfolio_preview {
    width: 864px;
    max-width: 864px;
    height: 378px;
    max-height: 378px;
    padding: 30px 30px 0px;
    @media (max-width: 650px) {
      padding: 30px 20px 0px;
    }
    .dot_menu {
      @media (max-width: 650px) {
        top: 6px;
      }
    }
    .heading {
      margin: 0;
      @media (max-width: 650px) {
        margin-bottom: 25px;
        position: relative;
      }
      .close_btn {
        @media (max-width: 650px) {
          top: -10px;
          right: 0;
        }
      }
    }
    .xs_sub_title {
      max-width: 350px;
    }
    .job_content {
      max-height: 275px;
      overflow-y: auto;
      @media (max-width: 650px) {
        max-height: 100%;
      }
      p {
        margin: 0;
        font-size: 14px;
        line-height: 1.43;
        color: var(--greyish-brown);
      }
    }
    .portfolio_wrappr {
      @media (max-width: 650px) {
        margin-bottom: 15px;
      }
      .media {
        cursor: auto;
        .portfolio_img {
          width: 392px;
          height: 314px;
        }
      }
      .react-multi-carousel-dot-list {
        bottom: 16px;
        border-radius: 12px;
        -webkit-backdrop-filter: blur(27px);
        backdrop-filter: blur(27px);
        background-color: rgba(45, 45, 45, 0.5);
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        padding: 4px 6px;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          &:last-child {
            button {
              margin: 0;
            }
          }
        }
      }
      .react-multi-carousel-dot.react-multi-carousel-dot--active button {
        background-color: var(--yellow) !important;
      }
      .react-multi-carousel-dot button {
        width: 6px;
        height: 6px;
      }
    }
  }
  &.vouch_modal {
    textarea {
      min-height: 154px !important;
    }
    .img_video {
      margin: 0 21px 21px 0;
    }
    .descr {
      max-height: 550px;
      @media (max-width: 1500px) {
        max-height: 440px;
      }
      @media (max-width: 1366px) {
        max-height: 350px;
      }
    }
  }
  &.profile_modal {
    width: 686px;
    max-width: 686px;
    &.vouch_modal {
      .inner_wrap {
        padding-bottom: 25px;
        @media (max-width: 1366px) {
          padding-bottom: 40px;
        }
        .upload_img_video {
          margin-bottom: 5px;
        }
      }
    }
    .inner_wrappr {
      max-width: 606px;
    }
    .form_wrapper {
      padding: 0;
      width: 540px;
      margin: 0 auto;
      max-width: 540px;
    }
    textarea {
      min-height: 280px;
      @media (max-width: 1366px) {
        min-height: 220px;
      }
      @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        min-height: 220px;
      }
    }
    .checkbox_wrap {
      label {
        font-size: 16px;
        font-family: "Neue Haas Grotesk Text Pro Roman";
        max-width: 550px;
      }
    }
    .full_btn {
      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }

  .back {
    top: 5px;
    transform: initial;
    @media (max-width: 650px) {
      top: 0;
    }
    &.bk_white {
      top: 10px;
      left: 10px;
      background: url("../images/icon-back-white.png") center no-repeat;
      z-index: 9;
      cursor: pointer;
    }
  }
  .md_heading {
    padding-left: 40px;
    .sub_title {
      margin-bottom: 5px;
    }
    .info_note {
      font-family: "Inter";
    }
  }
}

.ques_ans_modal,
.custom_modal {
  position: relative;
  background-color: rgba(22, 29, 74, 0.7);
  [aria-hidden="true"] {
    background-color: transparent !important;
  }
  .img_text_wrap {
    height: 100%;
  }
  .flex_col_sm_5 {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  .sub_title {
    @media (max-width: 650px) {
      font-size: 16px;
    }
  }
  .close_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 9;
    @include position(absolute, 0px, 10px, auto, auto);
    @media (max-width: 650px) {
      width: 30px;
      height: 30px;
      top: -6px;
      right: 10px;
    }
  }
  .inner_wrappr {
    max-width: 800px;
    margin: 0;
    padding: 0;
  }
  .heading {
    @include position(sticky, 0, auto, auto, auto);
    background-color: var(--white);
    z-index: 9;
  }

  .inner_wrap {
    max-height: 460px;
    overflow-y: auto;
    overflow-x: hidden;
    @media (max-width: 1500px) {
      max-height: 350px;
    }
    @media (max-width: 1280px) {
      max-height: 290px;
    }
    @media (max-width: 768px) {
      max-height: 90%;
    }
  }
  .fill_btn {
    margin-right: 32px;
    @media (max-width: 650px) {
      margin-right: 15px;
    }
  }
  .full_btn {
    @media (max-width: 650px) {
      width: 60%;
    }
  }
  .btn_wrap {
    height: 90px;
    position: relative;
    .bottom_btn {
      @include position(absolute, auto, auto, 0px, auto);
      @media (max-width: 1024px) {
        width: 100%;
        display: flex;
      }
      @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        position: relative;
      }

      .full_btn {
        @media (max-width: 1024px) {
          width: 200px;
          margin: 0 15px 0 0;
        }
      }
    }
  }
  .custom_btn {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 450px;
      display: flex;
      position: relative;
      .full_btn {
        width: 242px;
        margin: 0 15px 0 0;
      }
    }
    @media (max-width: 768px) {
      width: 400px;
      display: flex;
      .full_btn {
        width: 242px;
        margin: 0 15px 0 0;
      }
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 400px;
      display: flex;
      .full_btn {
        width: 242px;
        margin: 0 15px 0 0;
      }
    }
    @media (max-width: 650px) {
      width: 270px;
      .full_btn {
        width: 150px;
        margin: 0 15px 0 0;
      }
    }
  }
}

.filter_modal {
  overflow: hidden;
  .filter_btn {
    padding: 0 12px 0;
    background-color: var(--white);
    width: 95%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    @include position(absolute, auto, auto, 20px, 20px);
    .fill_btn {
      margin: 0;
    }
    .full_btn {
      @media (max-width: 650px) {
        width: 150px;
      }
    }
  }
}
//-------------------Question Answer/ Custom/ Filter model close------------------------//
//-------------------------Profile-----------------------//
.ques_count {
  max-width: 328px;
  margin: 0 0 25px;
  padding: 18px 15px 18px 24px;
  @include border-radius(12px);
  border: solid 1px rgba(160, 159, 167, 0.35);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  @media (max-width: 650px) {
    max-width: 100%;
  }
  .total {
    position: relative;
    color: var(--greyish-brown);
    padding-left: 30px;
    cursor: pointer;
    &::before {
      @include position(absolute, 50%, auto, auto, 0);
      transform: translateY(-50%);
      content: "";
      width: 20px;
      height: 20px;
      background: url("../images/ic-question.png") center no-repeat;
      background-size: contain;
    }
  }
  .new {
    color: var(--blue-two);
    cursor: pointer;
  }
}

//----------------Profile close----------------------//
// ----------------Recent Searches----------------- //
.selectd_input {
  background-color: #eef1f0;
  @media (max-width: 768px) {
    border-radius: 12px;
  }
  input {
    background-color: transparent;
  }
}

.recent_search {
  padding-bottom: 15px;
  .card {
    border-radius: 12px;
    border: none;
    padding: 15px 7px 15px 15px;
    transition: all ease-in 0.3s;
    background-color: #f6f6f6;
    position: relative;
    span {
      font-size: 14px;
      color: var(--greyish-brown);
      position: relative;
      font-family: "Neue Haas Grotesk Text Pro Roman";
      cursor: pointer;
      line-height: 1.43;
      letter-spacing: 0.3px;
    }
    &:hover,
    &.active {
      box-shadow: 2px 0 20px 0 rgba(46, 61, 73, 0.2);
    }
    .remove_card {
      @include position(absolute, -5px, -5px, auto, auto);
      overflow: initial;
      img {
        width: 18px;
        height: 18px;
      }
    }
    @media (max-width: 812px) {
      margin-bottom: 24px;
    }
    &.autosuggestion_icon {
      &:before {
        left: 5px;
      }
    }
    &.ico_txt_wrap {
      display: flex;
      min-height: 72px;
      .ico {
        width: 24px;
        height: 24px;
        padding: 2px;
        margin-right: 7px;
        background-image: linear-gradient(to top, #161d4a, #0b41a8);
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .f_column {
      width: 81%;
    }
  }
  .flex_col_sm_4 {
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 50%;
    }
  }
  span {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    &.name {
      font-family: "Inter";
      margin-top: 2px;
    }
  }
  &.auto_loc {
    padding: 0;
    .flex_col_sm_4 {
      width: 100%;
    }
    .card {
      padding: 11px 10px 11px 65px;
      background-color: transparent;
      &:hover {
        background-color: #f6f6f6;
      }
    }
  }
  &.select_sphere {
    ul {
      @media (max-width: 812px) {
        flex-flow: wrap !important;
      }
      li {
        width: 12% !important;
        margin: 0 12px 30px 12px;
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
          width: 20% !important;
        }
        @media (max-width: 650px) {
          width: 25% !important;
        }
        &::after {
          right: 10px;
          @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
            right: 20px;
          }
          @media (max-width: 768px) {
            right: -10px;
          }
          @media (max-width: 650px) {
            right: 0;
          }
          @media (max-width: 415px) {
            right: 8px;
          }
        }
      }
    }
  }
}

//------home recent search------//
.recent_search_wrap {
  .card {
    border: solid 1px rgba(160, 159, 167, 0.35);
    padding: 23px 10px 23px 65px;
  }
  .recent_search {
    padding-bottom: 50px;
  }
}

//------home recent search------//
// ----------------Recent Searches----------------- //

//----------------Sort/Range modal---------------------//
.fsp_modal {
  position: relative;
  ul {
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
    li {
      width: 50%;
      padding: 0 10px;
      margin: 0 0 24px;
    }
  }
  .sub_title {
    font-size: 20px;
    margin-bottom: 18px;
  }
  .close_btn {
    @include position(absolute, 30px, 30px, auto, auto);
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 9;
    img {
      width: 16px;
      height: 16px;
    }
  }
  &.range {
    .MuiMenu-paper {
      width: 480px;
      padding: 25px 32px 20px;
      border-radius: 3px;
      box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.14);
      background-color: var(--white);
      z-index: 99;
      margin: 65px 0 0;
      @media (max-width: 650px) {
        left: auto;
        right: -83px;
      }
    }
    .MuiList-root {
      position: initial;
      padding: 0;
      display: block;
    }
    .MuiSlider-thumb {
      border: 3px solid var(--yellow);
    }
    .MuiSlider-rail,
    .MuiSlider-track {
      height: 3px;
    }
    .per_day {
      font: 16px/1.33 "Neue Haas Grotesk Text Pro Roman";
      color: var(--blue);
      display: block;
    }
    .price {
      font: 14px/1.29 "Neue Haas Grotesk Text Pro Medium";
      color: var(--blue);
      margin-bottom: 8px;
      display: block;
      cursor: pointer;
      position: relative;
      &::after {
        width: 18px;
        height: 18px;
        content: "";
        @include position(absolute, 1px, auto, auto, auto);
        margin-left: 10px;
      }
      &.down {
        &::after {
          transform: rotate(90deg);
          background: url("../images/ic-next-blue.png") center no-repeat;
          background-size: contain;
        }
      }
      &.up {
        &::after {
          transform: rotate(-90deg);
          background: url("../images/ic-next-blue.png") center no-repeat;
          background-size: contain;
        }
      }
    }
    &.sort {
      .MuiMenu-paper {
        margin: 65px 0 0 90px;
      }
    }
    &.dropbox {
      .MuiMenu-paper {
        // margin: 300px 0 0 140px;
        width: 260px;
        label,
        .dropbox-button {
          font-size: 16px;
          color: var(--blue);
          cursor: pointer;
        }
        .dropbox-button {
          background-color: transparent;
          margin-top: 16px;
        }
      }
    }
  }
}

//----------------Sort/Range modal close---------------------//

//----------------Edit menu---------------------//
.edit_menu {
  @include position(absolute, 30px, -100%, auto, auto);
  transition: all ease-in 0.3s;
  opacity: 0;
  visibility: hidden;
  // width: 165px;
  width: 200px;
  padding: 16px 10px 16px 20px;
  border-radius: 3px;
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
  background-color: #f7f8fa;
  z-index: 99;
  margin: 0px 0 0 0px;
  box-shadow: none;
  .MuiList-root {
    position: initial;
    padding: 0;
    display: block;
  }
  ul {
    li {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      &.icon {
        padding-left: 32px;
        font: 14px "Neue Haas Grotesk Text Pro Medium";
        text-align: left;
        &:before {
          width: 24px;
          height: 24px;
        }
        &.edit {
          &::before {
            background: url("../images/ic-edit.png") center no-repeat;
          }
        }
        &.edit_line {
          &::before {
            background: url("../images/ic-edit-line.png") center no-repeat;
          }
        }
        &.edit_line_report {
          &::before {
            background: url("../images/ic-edit-line.png") center no-repeat;
          }
        }
        &.lodge {
          &::before {
            background: url("../images/ic-lodge.png") center no-repeat;
          }
        }
        &.delete {
          color: #ed1c24;
          &::before {
            background: url("../images/ic-delete.png") center no-repeat;
          }
        }
      }
    }
  }
}
.edit_menu_report {
  @include position(absolute, 15px, -100%, auto, auto);
  transition: all ease-in 0.3s;
  opacity: 0;
  visibility: hidden;
  width: 102px;
  padding: 10px;
  border-radius: 3px;
  text-align: center;
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
  background-color: #f7f8fa;
  z-index: 99;
  margin: 0px 0 0 0px;
  box-shadow: none;
  .MuiList-root {
    position: initial;
    padding: 0;
    display: block;
  }
  ul {
    li {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      &.icon {
        padding-left: 32px;
        font: 14px "Neue Haas Grotesk Text Pro Medium";
        text-align: left;
        &:before {
          width: 24px;
          height: 24px;
        }
        &.edit {
          &::before {
            background: url("../images/ic-edit.png") center no-repeat;
          }
        }
        &.edit_line {
          &::before {
            background: url("../images/ic-edit-line.png") center no-repeat;
          }
        }
        &.edit_line_report {
          &::before {
            background: url("../images/ic-edit-line.png") center no-repeat;
          }
        }
        &.lodge {
          &::before {
            background: url("../images/ic-lodge.png") center no-repeat;
          }
        }
        &.delete {
          color: #ed1c24;
          &::before {
            background: url("../images/ic-delete.png") center no-repeat;
          }
        }
      }
    }
  }
}

.dot_menu {
  @include position(absolute, 25px, 30px, auto, auto);
  cursor: pointer;
  width: 25px;
  height: 25px;
  z-index: 9;
  @media (max-width: 650px) {
    right: 10px;
    top: 15px;
  }
  &:hover {
    .edit_menu {
      opacity: 1;
      visibility: visible;
      right: 0;
    }
    .edit_menu_report {
      opacity: 1;
      visibility: visible;
      right: 0;
    }
  }
  &.r0 {
    right: 0;
    @media (max-width: 768px) {
      top: -20px;
    }
    @media (max-width: 650px) {
      top: -8px;
    }
  }
}
//----------------Edit menu close---------------------//

//---------------Success-img-text---------------//
.img_text_wrap {
  .full_image {
    position: relative;
    width: 100%;
    height: 100vh;
    @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0px 0px;
    }
    .short_info {
      @include position(absolute, 50%, auto, auto, 50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }
  .logo_img {
    width: 100px;
    height: 80px;
    margin: auto;
    padding-bottom: 180px;
    img {
      width: 87px;
      height: 70px;
    }
  }
  .tick_img {
    animation: tick_img 2s 0.5s forwards normal;
    opacity: 0;
    width: 20% !important;
    height: 30% !important;
    margin: auto !important;
    padding-bottom: 30px !important;
  }
  @keyframes tick_img {
    to {
      opacity: 1;
    }
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  .content {
    display: flex;
    flex-flow: column;
    align-items: center;
    .title {
      color: var(--white);
    }
    .show_label {
      margin-bottom: 50px;
      font-weight: 400;
      color: var(--white);
      text-align: center;
      max-width: 354px;
      &.msg {
        max-width: 262px;
        font-size: 18px;
        text-align: center;
        line-height: 1.33;
        @media (max-width: 650px) {
          font-size: 14px;
        }
      }
    }
    .lets_go_show_label {
      margin-bottom: 50px;
      font-weight: 400;
      color: var(--white);
      text-align: center;
      max-width: 370px;
      &.msg {
        font-size: 18px;
        text-align: center;
        line-height: 1.33;
        @media (max-width: 650px) {
          font-size: 14px;
        }
      }
    }
    .full_btn {
      margin-bottom: 0;
    }
    .btn_wrapr {
      display: flex;
      align-items: center;
      .fill_btn {
        width: 250px;
        @media (max-width: 650px) {
          width: 120px;
        }
        &.white_btn {
          background-color: var(--white);
          color: var(--blue);
          margin-left: 60px;
          @media (max-width: 650px) {
            margin-left: 20px;
          }
        }
      }
    }
  }
}

//------------Success-img-text close---------------//

//------------Center-Image-Text---------------//
.error_page_wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  .no_img {
    width: 600px;
    margin: 0 auto 30px;
    @media (max-width: 650px) {
      width: 300px;
    }
    img {
      width: 100%;
    }
  }
  .content {
    text-align: center;
    h1 {
      color: var(--blue);
      font: 30px/1.5 "Neue Haas Grotesk Text Pro Medium";
      margin-bottom: 20px;
    }
  }
}

//------------Center-Image-Text close---------------//
//----------------Under construction-------------------//
.under_construction_wrap {
  position: relative;
  text-align: center;
  .constrction_img {
    margin: 0 auto 15px;
    height: 390px;
    @media (max-width: 812px) {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  h2 {
    max-width: 40%;
    margin: 0 auto 10px;
    text-align: center;
    color: var(--blue);
    font: 24px/1.5 "Neue Haas Grotesk Text Pro Medium";
    @media (max-width: 1024px) {
      max-width: 52%;
    }
    @media (max-width: 650px) {
      max-width: 90%;
      font-size: 20px;
    }
  }
  .link {
    font-size: 18px;
  }
}

//----------------Under construction close-------------------//
//------------Select sphere---------------//
.select_sphere {
  &.form_wrapper {
    width: 100%;
    max-width: 640px;
    @media (max-width: 1366px) {
      max-width: 560px;
    }
    @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      max-width: 560px;
    }
    .fill_btn {
      width: 327px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      display: inline-block;
      text-align: center;
      margin: 0 13px 44px 13px;
      position: relative;
      cursor: pointer;
      @media (max-width: 650px) {
        margin: 0 8px 20px 8px;
      }
      &::after {
        width: 24px;
        height: 24px;
        content: "";
        @include position(absolute, -10px, -5px, auto, auto);
        background: url("../images/yellow-tick.png") center no-repeat;
        background-size: contain;
        opacity: 0;
      }
      &.active {
        .name {
          font-family: "Neue Haas Grotesk Text Pro Medium";
        }
        &::after {
          opacity: 1;
        }
      }
      &:hover {
        figure {
          border-color: var(--blue-two);
        }
      }
      figure {
        margin-bottom: 8px;
        width: 80px;
        height: 80px;
        background-image: linear-gradient(to top, #161d4a, #0b41a8);
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 650px) {
          width: 70px;
          height: 70px;
        }
        img {
          width: 32px !important;
          height: 32px !important;
          object-fit: contain;
        }
      }
      .name {
        font-size: 12px;
        line-height: 1.67;
        color: var(--blue);
        max-width: 85px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

//------------Select sphere close---------------//
//-------------Toast-----------------//
.body-message {
  @include position(fixed, 0px, 50px, auto, auto);
  width: 305px;
  min-height: 55px;
  margin: 15px 0;
  display: flex;
  background-color: #f0f3fa !important;
  box-shadow: 0 0 5px #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  @media (max-width: 1024px) {
    right: 12px;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
  &.success {
    background-color: var(--green);
  }
  &.failed {
    background-color: #ed1c24;
  }
  &.warning {
    background-color: #e0d861;
  }
  &.info {
    background-color: var(--blue-two);
  }
  .wrapppr {
    width: 100%;
  }
  .commn_para {
    margin: 0 0 7px 0;
    max-width: 92%;
  }
  .cross-icon {
    display: inline-block;
    @include position(absolute, -5px, -5px, auto, auto);
    cursor: pointer;
    width: 18px;
    height: 18px;
    img {
      width: 18px;
      height: 18px;
    }
  }
  .fill_btn {
    padding: 8px 20px;
    font-size: 12px;
    color: var(--white);
  }
  .notif {
    .not_img {
      width: 45px;
      height: 45px;
    }
    span {
      font-size: 12px;
      &.time {
        font-size: 11px;
      }
      &.who {
        font-family: "Neue Haas Grotesk Text Pro Medium";
      }
    }
  }
}

//-------------Toast close-----------------//
//--------------Loader----------------//
.loader {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @include position(fixed, 0px, auto, auto, 0px);
  background-color: #0000004f;
  z-index: 9999;
  &.active {
    display: flex;
  }
  figure {
    width: 250px;
    height: 250px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.page_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  figure {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

//-----------Loader close-------------//
//-------------------Home----------------//
.home_banner {
  .banner_img_img {
    position: relative;
    width: 100%;
    height: 410px;
    @media (max-width: 650px) {
      height: 390;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::before {
      content: "";
      background: url("../images/onboarding-clip.png") no-repeat;
      background-size: cover;
      background-position: bottom;
      width: 100%;
      height: 100px;
      @include position(absolute, 0px, auto, auto, 0px);
      @media (max-width: 768px) {
        display: none;
      }
      @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        display: none;
      }
    }
  }
  .banner_container {
    @include position(absolute, 20px, auto, auto, 50%);
    transform: translateX(-50%);
    width: 864px;
    z-index: 9;
    @media (max-width: 1024px) {
      width: 864px;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      top: 22px;
      width: 580px;
    }
    @media (max-width: 768px) {
      top: 30px;
      width: 650px;
    }
    @media (max-width: 650px) {
      width: 95%;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 560px;
    }
    .commn_para {
      color: var(--white);
      margin-bottom: 25px;
      font-size: 16px;
    }
    .view-btn {
      background-color: var(--white);
      border: 1px solid var(--white);
      color: var(--blue-two);
      padding: 14px 20px;
      max-width: 176px;
      width: 100%;
      @media (max-width: 650px) {
        padding: 12px 15px;
        max-width: 135px;
      }
      &:hover {
        background-color: transparent;
        color: var(--white);
      }
    }
  }
  .commn_para {
    font-size: 14px;
    line-height: 1.56;
    color: var(--blue);
    margin: 0 0 15px 0;
    @media (max-width: 650px) {
      font-size: 12px;
    }
  }
  .heading {
    font: 34px/1 "Neue Haas Grotesk Text Pro Medium";
    letter-spacing: 0.3px;
    color: var(--white);
    margin: 0 auto 5px;
    white-space: nowrap;
    overflow: hidden;
    @media (max-width: 650px) {
      font-size: 24px;
    }
  }
}

.date_value {
  input::placeholder {
    color: var(--blue);
  }
  #calender-input::placeholder {
    opacity: 1;
  }
}

.home_search {
  margin-bottom: 55px;
  position: relative;
  width: 864px;
  border-radius: 3px;
  z-index: 99;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
    margin-bottom: 35px;
  }
  .modal_srch_close {
    display: none;
  }
  .detect_icon {
    display: flex;
    align-items: center;
  }
  input {
    padding: 21px 26px 21px 40px;
    border-color: transparent;
    &:hover,
    &:focus,
    &.active {
      @media (max-width: 768px) {
        border-color: transparent !important;
      }
    }
  }
  .detect_icon_ltr {
    left: 15px;
    &.calendar {
      background: url("../images/ic-calendar.png") 50% no-repeat;
      background-size: contain;
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
  @media (max-width: 650px) {
    margin-bottom: 40px;
  }
  .search_wrapr {
    width: 100%;
    border-radius: 3px;
    border: solid 1px var(--light-gray);
    background-color: var(--white);
    position: relative;
    &.first_input {
      ul {
        li {
          @media (max-width: 650px) {
            width: 84%;
          }
          &.loc_box,
          &.date_box {
            display: none;
          }
        }
      }
    }
    &.first_input_builder {
      ul {
        width: calc(100% - 64px);
        li {
          @media (max-width: 650px) {
            width: 84%;
          }
          &.loc_box,
          &.date_box {
            display: none;
          }
        }
      }
    }
    ul {
      display: flex;
      flex: 1;
      @media (max-width: 768px) {
        flex-flow: column;
      }
      @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        flex-flow: column;
      }
      li {
        width: 30.8%;
        @media (max-width: 768px) {
          width: 100%;
        }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
          width: 100%;
        }
        &:last-child {
          @media (max-width: 768px) {
            width: 85%;
          }
        }
        &.categ_box {
          .selectd_input {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            input {
              padding: 21px 30px 21px 60px;
            }
          }
        }
        &.loc_box {
          input {
            padding: 21px 32px 21px 40px;
          }
        }
        &.date_box {
          input {
            padding: 21px 32px 21px 40px;
          }
        }
        &.categ_box_builder {
          width: 45%;
          @media (max-width: 770px) {
            width: 110%;
          }
          .selectd_input {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            input {
              padding: 21px 30px 21px 60px;
            }
          }
        }
        &.loc_box_builder {
          width: 27.5%;
          @media (max-width: 770px) {
            width: 100%;
          }
          input {
            padding: 21px 32px 21px 40px;
          }
        }
        &.date_box_builder {
          width: 27.5%;
          @media (max-width: 770px) {
            width: 100%;
          }
          input {
            padding: 21px 32px 21px 40px;
          }
        }
      }
      .search_btn {
        @include position(absolute, -1px, 0px, auto, auto);
        @media (max-width: 768px) {
          top: auto;
          bottom: 0px;
        }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
          top: auto;
          bottom: 0px;
        }
        .fill_btn,
        .outline_btn {
          padding: 12px 15px;
          width: 64px;
          height: 63px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 812px) {
            width: 62px;
            height: 62px;
          }
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
.top_search {
  background-color: var(--blue);
  width: 100%;
  height: 90px;
  @include position(fixed, 80px, auto, auto, 50%);
  transform: translateX(-50%);
  z-index: 99;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    position: absolute;
  }
  .home_search {
    margin: auto;
  }
  .modal_srch_close {
    @include position(absolute, auto, 9px, auto, auto);
    z-index: 99;
    background-color: transparent;
    padding: 5px;
    border-radius: 50%;
    display: none;
  }
  @media (max-width: 812px) {
    left: -100%;
    -webkit-transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
    &.active {
      left: 50%;
      top: 90px;
      width: 95%;
    }
    .modal_srch_close {
      display: block;
      top: -15px;
      right: 5px;
    }
  }
}

//---suggestions---//
.custom_autosuggestion {
  width: 100%;
  background-color: var(--white);
  border-radius: 3px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0 20px 20px 0 rgba(163, 163, 164, 0.2);
  @include position(absolute, 64px, 0px, auto, auto);
  padding: 10px 10px 10px 12px;
  min-height: 120px;
  z-index: 99;
  @media (max-width: 768px) {
    top: 60px;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    top: 69px;
  }
  &.location {
    width: 597px;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 100%;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      top: 120px;
    }
    .sub_title {
      margin: 20px 0 10px;
    }
  }
  .sub_title {
    font-size: 18px;
    margin-bottom: 18px;
  }
  transform: translateY(0%);
  -webkit-transform: translateY(0%);
  animation: slide 0.5s forwards;
  -webkit-animation: slide 0.5s forwards;
  @-webkit-keyframes slide {
    0% {
      opacity: 0;
      -webkit-transform: scale3d(0, 1, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
    }
  }
  @keyframes slide {
    0% {
      opacity: 0;
      transform: scale3d(0, 1, 0);
    }
    100% {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  .select_sphere {
    max-height: 320px;
    overflow-y: auto;
  }

  .drop_data {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-height: 320px;
    overflow-y: auto;
    li {
      display: flex;
      width: 100% !important;
      margin-bottom: 15px;
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;
      &:hover,
      &.active {
        background-color: rgba(0, 0, 0, 0.04);
      }
      .category {
        width: 40px;
        height: 40px;
        background-image: linear-gradient(to top, #161d4a, #0b41a8);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
        img {
          height: 28px;
          object-fit: contain;
        }
      }
      .details {
        width: 80%;
        .name {
          font-size: 14px;
          line-height: 1.43;
          color: var(--greyish-brown);
          font-family: "Neue Haas Grotesk Text Pro Roman";
          display: block;
        }
        .prof {
          font-size: 12px;
          line-height: 1.67;
          color: var(--greyish-brown);
        }
      }
    }
  }
}
.rdrDateRangePickerWrapper {
  width: 100%;
}
.rdrCalendarWrapper {
  width: 100%;
  .rdrMonths {
    margin: 0 -10px;
    flex-wrap: wrap;
    .rdrMonth {
      width: 50%;
      padding: 0 10px;
      @media (max-width: 650px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}

.location-btn {
  width: 280px;
  height: 56px;
  padding: 16px 32px;
  border-radius: 3px;
  background-color: var(--grey);
  display: flex;
  align-items: center;
  font: 14px/1.43 "Neue Haas Grotesk Text Pro Medium";
  text-align: center;
  color: #120e24;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    height: 41px;
    padding: 11px 30px;
  }
  .gps_icon {
    margin-right: 20px;
    @media (max-width: 768px) {
      margin-right: 15px;
    }
    img {
      @media (max-width: 768px) {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.blocked_note {
  background-color: #0b41a8b5;
  color: var(--white);
  padding: 15px;
  display: block;
  position: relative;
  &::before {
    content: "";
    @include position(absolute, -5px, auto, auto, 35px);
    width: 20px;
    height: 20px;
    background: #5278c1;
    transform: rotate(45deg);
  }
}

//---suggestions close---//
//-------------------Home close----------------//
//-----------------Tradie card--------------------//
.tradies_row {
  .flex_col_sm_4 {
    @media (max-width: 1024px) {
      width: 50%;
    }
    @media (max-width: 650px) {
      width: 100% !important;
    }
  }
}

.job_status {
  color: var(--blue-two);
  font: 10px/1.7 "Neue Haas Grotesk Text Pro Medium";
  letter-spacing: 0.3px;
  text-transform: uppercase;
  display: block;
  padding-left: 2px;
}

.tradie_card {
  // min-height: 285px;
  min-height: 130px;
  padding: 16px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  position: relative;
  margin-bottom: 20px;
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.02);
  }
  @media (max-width: 1024px) {
    width: 100%;
    margin-bottom: 20px;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
    margin-bottom: 20px;
  }
  .full_btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }
  .more_detail {
    @include position(absolute, 16px, 16px, auto, auto);
    width: 40px;
    height: 40px;
    background: url("../images/more.png") center no-repeat;
    background-size: contain;
    overflow: hidden;
    cursor: pointer;
    &.new_top {
      @include position(absolute, 58px, 20px, auto, auto);
    }
  }
  .tag_review {
    margin: 0 0 20px;
  }
  .user_wrap {
    display: flex;
    // margin: 0 0 24px;
    .u_img {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 16px;
      border: 1px solid #e6e6e6;
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
      }
      &.icon {
        border-radius: 4px;
        border: none;
        background-image: linear-gradient(to top, #161d4a, #0b41a8);
      }
    }
    .details {
      width: 70%;
      min-height: 57px;
      .name {
        display: block;
        font: 16px "Neue Haas Grotesk Text Pro Medium";
        color: var(--blue);
        margin-bottom: 2px;
      }
      .prof {
        font-size: 14px;
        line-height: 1;
        color: var(--greyish-brown);
        display: block;
        margin-bottom: 4px;
      }
      .job {
        font: 14px "Neue Haas Grotesk Text Pro Medium";
        color: var(--blue-two);
        display: block;
        margin-bottom: 4px;
      }
    }
  }
  .tags_wrap {
    ul {
      margin: 0;
    }
  }

  .count_wrap {
    display: flex;
    align-items: center;
    @include position(absolute, auto, 20px, 5px, auto);
    li {
      &.icon {
        padding-left: 30px;
        margin-left: 50px;
        &:before {
          width: 24px;
          height: 24px;
        }
        &.comment {
          &:before {
            background: url("../images/ic-comment.png") center no-repeat;
          }
        }
        &.view {
          &:before {
            background: url("../images/ic-view.png") center no-repeat;
          }
        }
      }
    }
  }
  &.posted_by {
    min-height: 40px !important;
    background-color: transparent;
    box-shadow: none;
    padding: 10px 0;
    .chat {
      @include position(absolute, 16px, 16px, auto, auto);
      width: 40px;
      height: 40px;
      background: url("../images/chat.png") center no-repeat;
      background-size: contain;
      overflow: hidden;
      @media (max-width: 650px) {
        right: 30px;
      }
    }
    &.view_more {
      position: relative;
      cursor: pointer;
      &:after {
        right: 0;
        top: 40%;
      }
      &:hover {
        &:after {
          right: -20px;
          left: auto;
          top: 40%;
          @media (max-width: 650px) {
            right: -4px;
          }
        }
      }
    }
    .user_wrap {
      .details {
        width: 60%;
        min-height: auto;
        .name {
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
  &.quote_card {
    min-height: 187px;
  }
}

.job_info {
  background-color: #f7f8fa;
  padding: 22px 16px;
  margin-bottom: 15px;
  @media (max-width: 812px) {
    padding: 10px 15px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    li {
      display: inline-block;
      flex: 1;
      @media (max-width: 812px) {
        margin: 5px 0;
        flex: 1 1 50%;
      }
      &.icon {
        padding-left: 20px;
        &:before {
          width: 16px;
          height: 16px;
        }
        &.clock {
          &::before {
            background: url("../images/ic-clock.png") center no-repeat;
          }
        }
        &.dollar {
          &::before {
            background: url("../images/ic-dollar.png") center no-repeat;
          }
        }
        &.location {
          &::before {
            background: url("../images/ic-location.png") center no-repeat;
          }
        }
        &.calendar {
          &::before {
            background: url("../images/ic-calendar.png") center no-repeat;
          }
        }
      }
    }
  }
}

//-----------------Tradie card close--------------------//
//---------------------Categories--------------------------//
.home_categories {
  padding: 40px 0;
  position: relative;
}

.categories {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  margin-bottom: 40px;
  li {
    margin: 0;
    display: inline-block;
    width: 12%;
    @media (max-width: 768px) {
      margin: 0 15px 20px;
      width: 20%;
    }
    @media (max-width: 650px) {
      width: 24%;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      margin: 0 20px 20px 12px;
      width: 15.5%;
    }
    .categ_card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      &:hover,
      &.active {
        .categ_name {
          color: var(--blue-two);
        }
      }
      .categ_img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        background-color: var(--grey);
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
          width: 80px;
          height: 80px;
        }
        img {
          width: 32px;
          height: 32px;
          object-fit: contain;
          transition: all ease-in 0.5s;
          -moz-transition: all ease-in 0.5s;
          -ms-transition: all ease-in 0.5s;
          -o-transition: all ease-in 0.5s;
          -webkit-transition: all ease-in 0.5s;
        }
      }
      .categ_name {
        font: 12px/1.29 "Neue Haas Grotesk Text Pro Medium";
        text-align: center;
        color: var(--greyish-brown);
      }
    }
  }
}
.home_job_categories {
  padding: 40px 0;
  position: relative;
  .page_loader {
    width: 1216px;
    @media (max-width: 1366px) {
      max-width: 1150px;
    }
    @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      max-width: 1020px;
    }
    @media (max-width: 768px) {
      max-width: 750px;
    }
    @media (max-width: 650px) {
      max-width: 350px;
    }
  }
  .job_categories {
    display: block;
    text-align: center;
    .draw {
      padding: 20px;
      margin: 0 15px;
      .name {
        font-size: 18px;
        @media (max-width: 650px) {
          font-size: 16px;
        }
      }
    }
  }
  .select_sphere {
    ul {
      li {
        margin: 0;
      }
    }
  }
}
.job_categories {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 40px;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    background-color: var(--grey);
    border-radius: 3px;
    margin: 0px 20px 20px 0;
    cursor: pointer;
    // &:last-child{
    // 	margin: 0;
    // }
    @media (max-width: 650px) {
      margin: 0px 10px 10px 0 !important;
      padding: 10px 5px !important;
    }
    border: 2px solid var(--grey);
    &.active {
      border-color: var(--blue-two);
    }
    .type_icon {
      width: 22px;
      height: 22px;
      margin-right: 8px;
      img {
        width: 22px;
        height: 22px;
      }
    }
    .name {
      font-size: 14px;
      line-height: 1.11;
      color: var(--blue);
    }
  }
}

//------------------Categories close-----------------------//
// ----------Recent Search Categories-------------//
.searched_categories {
  padding: 0;
  position: relative;
  .categories {
    li {
      .categ_card {
        .categ_img {
          width: 80px;
          height: 80px;
          margin-bottom: 10px;
          img {
            width: 28px;
            height: 28px;
          }
        }
        .categ_name {
          font-size: 12px;
        }
      }
    }
  }
}

// ----------Recent Search Categories-------------//
//-----------------Popular Tradies----------------------//
.popular_tradies {
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
  li {
    display: inline-block;
    width: 16.66666667%;
    padding: 0 10px;
    text-align: center;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
    @media (max-width: 1024px) {
      width: 20%;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 25%;
    }
    @media (max-width: 768px) {
      width: 25%;
    }
    @media (max-width: 650px) {
      width: 50%;
    }
    &:hover {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
      background-color: var(--white);
    }
    .tradies_img {
      width: 102px;
      height: 102px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto 16px;
      @media (max-width: 650px) {
        width: 80px;
        height: 80px;
        margin: 0 auto 12px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name {
      color: var(--blue);
      font: 20px "Neue Haas Grotesk Text Pro Medium";
      display: block;
      margin-bottom: 8px;
      @media (max-width: 768px) {
        font-size: 18px;
        margin-bottom: 5px;
      }
      @media (max-width: 650px) {
        font-size: 16px;
      }
    }
    .post {
      font-size: 14px;
      line-height: 1.29;
      color: var(--blue);
      @media (max-width: 650px) {
        font-size: 12px;
        line-height: 1;
      }
    }
  }
}

//-------------Popular Tradies close-----------------//

//----------------Post job and detail----------------------//
.location-search-input {
  height: 50px;
  display: block !important;
}
.edit_delete {
  @include position(absolute, 12px, 12px, auto, auto);
  display: flex;
  align-items: center;
  &.tr {
    top: 0;
    right: 0;
  }
  span {
    margin-left: 10px;
    width: 24px;
    height: 24px;
    display: inline-block;
    cursor: pointer;
    z-index: 9;
  }
  .edit {
    background: url("../images/ic-edit-blue.png") center no-repeat;
    background-size: contain;
  }
  .editdark {
    background: url("../images/ic-edit.png") center no-repeat;
    background-size: contain;
  }
  .delete {
    background: url("../images/ic-cancel-blue.png") center no-repeat;
    background-size: contain;
  }
  .remove {
    background: url("../images/ic-bin.png") center no-repeat;
    background-size: contain;
  }
}
.milestones {
  margin-bottom: 30px;
  li {
    border-radius: 3px;
    border: solid 1px var(--grey);
    min-height: 95px;
    position: relative;
    padding: 11px 8px;
    margin-bottom: 16px;
    &::before {
      @include position(absolute, 38px, auto, auto, 20px);
      border: 1px dashed var(--grey);
      height: 45%;
      width: 0px;
      z-index: -1;
      content: "";
    }
    &:hover {
      box-shadow: 0 5px 30px 0 rgba(79, 79, 118, 0.15);
    }
    // &:last-child{
    // 	margin-bottom: 0;
    // }
    label {
      font-size: 16px;
      color: var(--blue);
      font-family: "Neue Haas Grotesk Text Pro Roman";
      width: 100%;
      max-width: 400px;
      @media (max-width: 650px) {
        max-width: 270px;
      }
    }
    .info {
      display: flex;
      flex-flow: column;
      padding-left: 32px;
      font-size: 14px;
      color: var(--greyish-brown);
      span {
        margin: 3px 0;
      }
    }
  }
}

.milestone_templates {
  display: flex;
  flex-flow: column;
  padding: 12px 0px 0px;
  li {
    background-color: #f7f8fa;
    padding: 10px 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
    .name {
      color: var(--blue);
      font: 14px/1.43 "Neue Haas Grotesk Text Pro Medium";
      max-width: 500px;
      display: block;
      width: 100%;
      position: relative;
      padding-left: 40px;
      @media (max-width: 1366px) {
        max-width: 360px;
      }
      @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        max-width: 360px;
      }
      @media (max-width: 650px) {
        max-width: 250px;
        padding-left: 30px;
      }
      &:before {
        @include position(absolute, 50%, auto, auto, 0px);
        transform: translateY(-50%);
        content: "";
        width: 24px;
        height: 24px;
        background: url("../images/ic-template.png") center no-repeat;
        background-size: contain;
        @media (max-width: 650px) {
          width: 20px;
          height: 20px;
        }
        @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
          width: 18px;
          height: 18px;
        }
      }
    }
    .count {
      font-size: 12px;
      line-height: 1.4;
      text-align: right;
      color: var(--greyish-brown);
      span {
        font-size: 10px;
        color: var(--dark-grey);
        display: block;
      }
    }
  }
}
.ques_btn {
  max-width: 345px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  img {
    margin-right: 15px;
  }
}

.job_milestone {
  max-width: 345px;
  margin: 0 0 20px;
  min-height: 90px;
  @media (max-width: 650px) {
    min-height: 70px;
    max-width: 100%;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    span {
      font-size: 16px;
      color: var(--blue);
      line-height: 1;
      @media (max-width: 650px) {
        font-size: 16px;
      }
    }
  }
}
//----------------Post job and detail close----------------------//

//----------------Search result----------------------//
.search_result {
  padding-top: 80px;
  @media (max-width: 812px) {
    padding-top: 0;
  }
  .flex_col_sm_8 {
    @media (max-width: 812px) {
      width: 50%;
    }
    @media (max-width: 650px) {
      width: 70%;
    }
  }
  .flex_col_sm_4 {
    @media (max-width: 650px) {
      width: 30%;
    }
  }

  .result_heading {
    margin-bottom: 40px;
    .flex_row {
      align-items: flex-end;
    }
  }
  .title {
    margin-bottom: 24px;
    @media (max-width: 650px) {
      margin-bottom: 20px;
    }
    .count {
      margin: 8px 0 0;
      font-size: 16px;
      color: var(--dark-grey);
      line-height: 1.13;
      display: block;
      font-family: "Inter";
      @media (max-width: 650px) {
        font-size: 14px;
      }
    }
  }
  .filters_wrapr {
    position: relative;
    .filters_row {
      display: flex;
      li {
        display: inline-block;
        margin-right: 24px;
        position: relative;
        @media (max-width: 650px) {
          margin-right: 12px;
        }
        a {
          font-size: 14px;
          font-family: "Neue Haas Grotesk Text Pro Roman";
          line-height: 1.43;
          color: var(--blue);
          padding: 13px 15px;
          border-radius: 6px;
          border: 1px solid var(--grey);
          background-color: var(--grey);
          display: flex;
          align-items: center;
          @media (max-width: 650px) {
            font-size: 12px;
            padding: 7px 10px;
            border-radius: 4px;
          }
          &:hover {
            border-color: var(--blue-two);
          }
          &.active,
          &:focus,
          &:active {
            background-color: var(--blue-two);
            color: var(--white);
            border-color: var(--blue-two);
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            @media (max-width: 650px) {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }
  .map_btn {
    font: 14px/1.43 "Neue Haas Grotesk Text Pro Medium";
    color: var(--blue);
    padding: 13px 15px;
    border-radius: 6px;
    border: 1px solid var(--grey);
    background-color: var(--grey);
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    @media (max-width: 650px) {
      font-size: 12px;
      padding: 7px 10px;
      border-radius: 4px;
    }
    &:hover,
    &.active {
      border-color: var(--blue-two);
    }
    img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
      @media (max-width: 650px) {
        width: 15px;
        height: 15px;
      }
    }
  }
  .tradies_row {
    .card_col {
      flex: 1 1 50%;
      @media (max-width: 1000px) {
        flex: 1 1 100%;
      }
      .flex_col_sm_6 {
        width: 70%;
      }
    }
    .map_col {
      flex: 1 1 50%;
      z-index: 9;
      @media (max-width: 1000px) {
        flex: 1 1 100%;
      }
    }
    .flex_col_sm_6 {
      @media (max-width: 768px) {
        width: 50%;
      }
      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }
  .mob_srch_option {
    display: none;
    @media (max-width: 812px) {
      display: flex;
      margin-bottom: 15px;
    }
    .flex_col_sm_6 {
      @media (max-width: 768px) {
        width: 50%;
      }
    }
  }
}
.map_stick {
  height: 100vh;
  @include position(fixed, 206px, 0px, auto, auto);
  width: 60%;
  transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 99;
  @media (max-width: 1000px) {
    width: 100%;
    top: 80px;
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    top: 80px;
  }
  + div {
    height: 100%;
  }
  .close_map {
    @include position(absolute, 20px, auto, auto, 20px);
    z-index: 9;
    cursor: pointer;
    img {
      width: 70px;
      height: 70px;
    }
  }
}
.preview_card {
  .tradie_card {
    max-width: 372px;
    min-height: 145px;
    margin-bottom: 0;
    padding: 0px;
    @media (max-width: 650px) {
      flex-flow: row;
      padding: 4px;
    }
    &:hover {
      box-shadow: none;
    }
    .more_detail {
      top: 0px;
      right: 5px;
      width: 35px;
      height: 35px;
    }
    .job_info {
      padding: 8px 10px;
      margin: 0;
      li {
        flex: 1 1 50%;
        margin: 5px 0;
        &.location {
          padding-right: 5px;
        }
      }
    }
  }
}
//----------------Search result close----------------------//

//----------------Data not found---------------------//
.no_record {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-flow: column;
  width: 100%;
  height: 100%;
  &.align_centr {
    height: 95%;
  }
  .no_img {
    height: 100px;
    margin: 0 auto 15px;
    width: 100%;
    @media (max-width: 1366px) {
      height: 90px;
    }
    @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      height: 90px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .no_data_img {
    height: 80px;
    margin: 0 auto 5px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  span {
    //  text-align: center;
    // color: #2f5cb5;
    // margin: 0 0 10px;
    // font: 16px/1 "Neue Haas Grotesk Text Pro Medium";
  }
}
//----------------Data not found close---------------------//

//----------------Job Dashboard-------------------//
.p-b-0 {
  padding-bottom: 0;
}

.mob_side_nav {
  @include position(absolute, 12px, 15px, auto, auto);
  width: 22px;
  height: 22px;
  cursor: pointer;
  display: none;
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 991px) {
    display: block;
  }
}

.side_nav_col {
  width: 100%;
  max-width: 390px;
  height: 100%;
  background-color: #f7f8fa;
  padding: 48px 0px 20px 0px;
  // margin: 0 0 0 -122px;
  position: relative;
  @media (min-width: 992px) {
    position: sticky;
    top: 80px;
    height: calc(100vh - 80px);
  }
  @media (max-width: 1366px) {
    max-width: 278px;
  }
  //    @media (max-width: 1150px) {
  //    width: 390px;
  //  }
  @media (max-width: 991px) {
    position: fixed;
    transition: 0.4s all;
    left: -100%;
    margin: 0;
    padding: 0;
    width: 0;
  }
  &.active {
    @media (max-width: 991px) {
      left: 0;
      width: 350px;
      z-index: 99;
      padding: 32px 0 0;
      .close_nav {
        display: block;
      }
    }
    @media (max-width: 650px) {
      width: 280px;
      padding: 15px;
    }
  }
  .close_nav {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 12px;
    border: solid 1px rgba(160, 159, 167, 0.35);
    display: flex;
    justify-content: center;
    align-items: center;
    @include position(absolute, 18px, 18px, auto, auto);
    z-index: 999;
    display: none;
    @media (max-width: 650px) {
      right: 7px;
      top: 7px;
      border: none;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
  .title {
    margin: 0 0 40px 120px;
    @media (max-width: 1366px) {
      margin: 0 0 15px 40px;
    }
    @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      margin: 0 0 15px 120px;
    }
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      margin: 0 0 15px 100px;
    }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      margin: 0 0 25px 130px;
    }
    @media (max-width: 768px) {
      margin: 0 0 15px 50px;
    }
    @media (max-width: 650px) {
      margin: 0 0 15px 10px;
    }
  }
}

.dashboard_menu {
  li {
    .icon {
      padding: 19px 10px 19px 160px;
      color: var(--blue);
      display: block;
      margin-bottom: 12px;
      @media (max-width: 1366px) {
        padding: 15px 10px 17px 75px;
        margin-bottom: 8px;
      }
      @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        padding: 15px 10px 17px 160px;
        margin-bottom: 8px;
      }
      @media (max-width: 768px) {
        padding: 15px 10px 17px 100px;
      }
      @media (max-width: 650px) {
        padding: 12px 10px 12px 40px;
      }
      &:hover {
        background: #ffffff;
      }
      &.active {
        font-family: "Neue Haas Grotesk Text Pro Medium";
        background: #ffffff;
      }
      &:before {
        width: 24px;
        height: 24px;
        left: 120px;
        @media (max-width: 1366px) {
          left: 40px;
        }
        @media (max-width: 1150px) {
          left: 40px;
        }
        @media (max-width: 768px) {
          left: 40px;
        }
        @media (max-width: 650px) {
          width: 20px;
          height: 20px;
          background-size: contain !important;
          left: 5px;
        }
      }
      &.star {
        &::before {
          background: url("../images/ic-star.png") center no-repeat;
        }
      }
      &.applied {
        &::before {
          background: url("../images/checked-1.png") center no-repeat;
        }
      }
      &.past {
        &::before {
          background: url("../images/date-1.png") center no-repeat;
        }
      }
      &.new,
      &.open {
        &::before {
          background: url("../images/ic-job.png") center no-repeat;
        }
      }
      &.approved {
        &::before {
          background: url("../images/ic-template.png") center no-repeat;
        }
      }
      &.applicants {
        &::before {
          background: url("../images/ic-profile.png") center no-repeat;
        }
      }
      &.wallet {
        &::before {
          background: url("../images/ic-wallet.png") center no-repeat;
        }
      }
      &.settings {
        &::before {
          background: url("../images/ic-setting.png") center no-repeat;
        }
      }
      &.chat {
        &::before {
          background: url("../images/ic-message.png") center no-repeat;
        }
      }
      &.template {
        &::before {
          background: url("../images/ic-template.png") center no-repeat;
        }
      }
      &.tnc {
        &::before {
          background: url("../images/ico-terms.png") center no-repeat;
        }
      }
      .menu_txt {
        position: relative;
        white-space: pre-wrap;
        max-width: 145px;
        display: inline-block;
        color: var(--blue);
        cursor: pointer;
      }
    }
  }
  .badge_count {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--yellow);
    @include position(absolute, 50%, -40px, auto, auto);
    transform: translateY(-50%);
    color: var(--blue);
    font: 14px "Neue Haas Grotesk Text Pro Medium";
  }
}
.detail_col {
  max-width: 1135px;
  width: 100%;
  padding: 48px 10px 0;
  // margin-left: 300px;
  @media (max-width: 1500px) {
    max-width: 1075px;
    width: 100%;
  }
  @media (max-width: 1366px) {
    max-width: 970px;
    width: 100%;
  }
  @media (max-width: 1200px) {
    width: 100%;
    max-width: 705px;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 30px 20px 0 !important;
    margin-left: 0 !important;
    .flex_col_sm_2 {
      width: 20%;
    }
    .flex_col_sm_3 {
      width: 50%;
    }
  }
  @media (max-width: 650px) {
    padding: 30px 10px 0 !important;
    .flex_col_sm_6 {
      width: 100%;
    }
    .flex_col_sm_2 {
      width: 100%;
      text-align: center;
    }
  }
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    padding: 20px;
    .flex_col_sm_6 {
      width: 100%;
    }
    .flex_col_sm_2 {
      width: 100%;
      text-align: center;
    }
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .flex_col_sm_6,
    .flex_col_sm_8 {
      width: 100%;
    }
  }
  .flex_col_sm_9 {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  .tradies_row {
    .flex_col_sm_6 {
      @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        width: 100%;
      }
      @media (max-width: 768px) {
        width: 50%;
      }
      @media (max-width: 650px) {
        width: 100%;
      }
    }
  }
  .tradie_card {
    min-height: 337px;
    .user_wrap {
      margin: 0 0 16px;
    }
    .count_wrap {
      justify-content: space-around;
      width: 100%;
    }
  }
  .job_info {
    padding: 15px;
    margin-bottom: 20px;
    ul {
      li {
        margin: 5px 0;
        flex: 1 1 50%;
      }
    }
  }
  .relate {
    .back {
      width: 20px;
      height: 20px;
    }
    .remove_icon {
      right: 0px !important;
      @media (max-width: 768px) {
        right: 50px !important;
      }
    }
    .edit_icon {
      right: 30px;
      @media (max-width: 768px) {
        right: 80px;
      }
    }
    .dot_menu {
      top: 0;
      right: 10px;
      @media (max-width: 650px) {
        top: -2px;
        right: 40px;
      }
    }
    .xs_sub_title {
      font-size: 16px;
      padding-left: 30px;
      max-width: 86%;
      height: 20px;
      @media (max-width: 650px) {
        max-width: 80%;
      }
    }
    .edit_delete {
      top: 0px;
      right: 0px;
    }
  }
  hr {
    width: 1px;
    height: 100vh;
    @include position(absolute, auto, auto, auto, 50%);
    transform: translateX(95px);
    background-color: #f7f8fa;
    margin: 0;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .total_payment {
    margin: 48px 0 32px;
    max-width: 250px;
    @media (max-width: 650px) {
      max-width: 100%;
      margin: 30px 0 25px;
    }
    span {
      font-family: "Neue Haas Grotesk Text Pro Medium";
    }
  }
  .bank_btn {
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 17px 20px;
    border: 1px solid var(--blue-two);
    margin-bottom: 40px;
    @media (max-width: 650px) {
      width: 100%;
      padding: 12px 20px;
      margin-bottom: 30px;
    }
    img {
      margin-right: 30px;
      @media (max-width: 650px) {
        width: 18px;
        height: 18px;
        margin-right: 20px;
      }
    }
  }
  .upload_img_video {
    margin-bottom: 30px;
    .img_video {
      margin: 0 16px 0px 0;
    }
  }
}
.profile_info {
  padding: 48px 0px 0 40px;
  @media (max-width: 768px) {
    margin-top: 15px;
  }

  .title_view_wrap {
    position: relative;
    @media (max-width: 768px) {
      font-size: 12px;
    }
    .view_profile {
      display: flex;
      align-items: center;
      @include position(absolute, 0px, 0px, auto, auto);
      font: 14px "Neue Haas Grotesk Text Pro Medium";
      color: var(--blue-two);
      @media (max-width: 768px) {
        font-size: 12px;
      }
      &:hover {
        color: var(--blue);
      }
      img {
        margin-right: 10px;
        @media (max-width: 768px) {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }
  }
  .job_progress_wrap {
    .approval_info {
      top: 25px;
      right: -40px;
      font-size: 12px;
      color: var(--blue);
    }
  }
  .title {
    position: relative;
    margin-bottom: 12px;
    max-width: 55%;
    min-height: 37px;
    padding-right: 35px;
    @media (max-width: 768px) {
      max-width: 60%;
    }
    @media (max-width: 650px) {
      max-width: 55%;
    }
    .edit_icon {
      right: 10px;
    }
  }
  .tagg {
    color: var(--blue);
  }
  .review_job {
    li {
      width: 156px;
    }
  }
  .tags_wrap {
    ul {
      margin: 0;
    }
  }
  .portfolio_wrappr {
    max-width: 600px;
    .media {
      margin: 0 20px 20px 0;
      @media (max-width: 650px) {
        margin: 0 18px 18px 0;
      }
    }
    .upload_media {
      cursor: pointer;
      @media (max-width: 650px) {
        width: 145px;
        height: 145px;
        img {
          width: 145px;
          height: 145px;
        }
      }
    }
  }
  .flex_col_sm_8 {
    @media (max-width: 1024px) {
      width: 58.333333%;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  .flex_col_sm_4 {
    @media (max-width: 1024px) {
      width: 41.666667%;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  .p_settings {
    .inner_title {
      margin-bottom: 5px;
    }
    .form_label {
      color: #120e24;
      margin: 0;
    }
    .info_note {
      color: var(--dark-grey);
      line-height: 1.4;
    }
  }
  .support_categ {
    margin: 35px 0;
    .radio_wrap {
      label {
        font-family: "Neue Haas Grotesk Text Pro Roman";
      }
    }
  }
}

.id_info {
  position: relative;
  padding-left: 30px;
  margin-bottom: 30px;
  max-width: 280px;
  cursor: pointer;
  &::before {
    content: "";
    @include position(absolute, 2px, auto, auto, 0);
    width: 22px;
    height: 22px;
    background: url("../images/information-line.svg") center no-repeat;
    background-size: contain;
  }
}

.verificationid_list {
  padding-left: 30px;
  li {
    list-style-type: disc;
    margin-bottom: 10px;
    display: list-item;
  }
}

.id_verified {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 12px;
  }
}

.applicants_row {
  .flex_col_sm_4 {
    width: 50%;
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
      width: 100%;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}

.card_btn {
  padding: 16px 10px;
  margin-bottom: 24px;
  border-radius: 3px;
  border: solid 1px #0b41a8;
  background-color: #f7f8fa;
  position: relative;
  display: block;
  font: 14px "Neue Haas Grotesk Text Pro Medium";
  span {
    display: inline-block;
    font-family: "Inter";
    margin: 0;
  }
  .pos {
    @include position(absolute, 50%, auto, auto, auto);
    transform: translateY(-50%);
    height: 25px;
    &.card {
      left: 12px;
    }
    &.check {
      right: 0px;
    }
    ~ img {
      right: 12px !important;
    }
  }
}

.payment_note {
  font-size: 14px;
  line-height: 1.43;
  color: var(--greyish-brown);
  padding-left: 30px;
  position: relative;
  display: block;
  margin-bottom: 30px;
  &::before {
    @include position(absolute, 0px, auto, auto, 0px);
    content: "";
    width: 24px;
    height: 24px;
    background: url("../images/ic-dollarr.png") center no-repeat;
    background-size: contain;
    @media (max-width: 650px) {
      width: 20px;
      height: 20px;
    }
  }
}
.job_progress_wrap {
  padding: 8px 0;
  .progress_wrapper {
    position: relative;
  }
  @keyframes approval_info {
    to {
      opacity: 1;
    }
  }
  @keyframes approval_img {
    to {
      opacity: 1;
    }
  }
  .approval_info {
    @include position(absolute, 2px, 0px, auto, auto);
    animation: approval_info 1.5s 2s forwards;
    opacity: 0;
    font: 10px/1.8 "Neue Haas Grotesk Text Pro Medium";
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
    color: var(--blue-two);
    text-transform: uppercase;
    img {
      animation: approval_img 1s 3s forwards;
      opacity: 0;
      margin-right: 5px;
      width: 12px;
      height: 12px;
      object-fit: none;
    }
  }
}

.time_spent {
  display: block;
  max-width: 280px;
  width: 100%;
  @media (max-width: 650px) {
    max-width: 100%;
  }
  input {
    width: 215px;
    @media (max-width: 650px) {
      width: 80%;
    }
  }
  .detect_icon {
    font-family: "Neue Haas Grotesk Text Pro Roman";
  }
}
.pending_info {
  margin-top: 15px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
}

.milestones_check {
  margin: 20px 0px;
  li {
    min-height: 64px;
    position: relative;
    margin-bottom: 30px;
    &::before {
      @include position(absolute, 32px, auto, auto, 11px);
      border: 1px dashed var(--grey);
      height: 80%;
      width: 0px;
      z-index: -1;
      content: "";
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
    &.inactive {
      &::before {
        display: none;
      }
      .full_btn {
        width: 327px;
      }
    }
    &.active {
      .circle_stepper {
        span {
          opacity: 1;
          visibility: visible;
          width: 12px;
          height: 12px;
          background-color: var(--blue-two);
          display: inline-block;
          border-radius: 50%;
        }
      }
      &::before {
        display: none;
      }
      .full_btn {
        margin-top: 16px;
        width: 250px;
      }
    }
    &.check {
      &::before {
        border-color: var(--blue-two);
      }
      .circle_stepper {
        border: none;
        span {
          opacity: 1;
          visibility: visible;
          display: inline-block;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: var(--blue-two) url("../images/checked-2.png") center
            no-repeat;
          background-size: contain;
        }
      }
      .info {
        label,
        span {
          color: var(--dark-grey);
        }
      }
    }
    &.declined {
      &::before {
        display: none;
      }
      .circle_stepper {
        border: none;
        span {
          opacity: 1;
          visibility: visible;
          display: inline-block;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: var(--blue-two) url("../images/ic-exclamation.png") center
            no-repeat;
          background-size: contain;
        }
      }
    }
    .circle_stepper {
      @include position(absolute, 0px, auto, auto, 0px);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: solid 2px var(--grey);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
        opacity: 0;
        visibility: hidden;
      }
    }
    .info {
      padding-left: 40px;
      margin-bottom: 15px;
      label {
        font-size: 16px;
        color: var(--blue);
        font-family: "Neue Haas Grotesk Text Pro Roman";
        width: 100%;
        max-width: 400px;
        display: block;
      }
      span {
        margin: 3px 0;
        display: block;
        font-size: 14px;
      }
      .fill_btn {
        margin-top: 15px;
      }
    }
  }
}

.change_req {
  max-width: 100% !important;
}
.decline_reason,
.change_req {
  background-color: #f6f7f9;
  margin: 15px 0;
  padding: 30px 10px 30px 15px;
  max-width: 327px;
  position: relative;
  .show_label {
    font-family: "Neue Haas Grotesk Text Pro Roman";
    color: var(--blue);
  }
  .decline_media {
    li {
      margin: 0;
      &::before {
        display: none;
      }
    }
    .react-multiple-carousel__arrow {
      min-width: 30px;
      min-height: 30px;
      &.react-multiple-carousel__arrow--left {
        &::before {
          width: 18px;
          height: 18px;
        }
      }
      &.react-multiple-carousel__arrow--right {
        &::before {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .upload_img_video {
    margin: 0;
    .img_video {
      width: 140px;
      height: 122px;
      margin: 0;
    }
  }
  input,
  textarea {
    background-color: transparent;
  }
  .delete_quote {
    color: #ed1c24;
    position: absolute;
    right: 10px;
    top: 20px;
    display: flex;
    flex-flow: column-reverse;
    align-items: center;
    font-size: 13px;
    font-family: "Neue Haas Grotesk Text Pro Roman";
    cursor: pointer;
    img {
      margin-bottom: 5px;
    }
  }
  .edit_delete {
    top: 20px;
  }
}
.total_quote {
  margin: 0 0 55px 150px;
  text-align: right;
}

.view_quote {
  display: inline-block;
  color: var(--blue-two);
  font: 13px "Neue Haas Grotesk Text Pro Roman";
  background-color: #dfe5ef;
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
}

.reason_wrap {
  margin-bottom: 40px;
  .f_spacebw {
    align-items: initial;
    margin-bottom: 24px;
    .checkbox_wrap {
      flex: 1;
      label {
        font-size: 16px;
        color: var(--blue);
        font-family: "Neue Haas Grotesk Text Pro Roman";
        margin: 0;
      }
    }
  }
}

.payment_details {
  min-height: 175px;
  padding: 24px 15px 20px 24px;
  background-color: #f6f7f9;
  margin-bottom: 30px;
  .f_spacebw {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.bank_detail {
  position: relative;
  padding-left: 50px;
  display: flex;
  flex-flow: column;
  margin-bottom: 100px;
  &::before {
    @include position(absolute, 50%, auto, auto, 0px);
    transform: translateY(-50%);
    content: "";
    width: 40px;
    height: 40px;
    background: url("../images/ic-bank.png") center no-repeat;
    background-size: contain;
  }
  span {
    margin: 0;
  }
  &.view_more {
    &:hover {
      &::after {
        right: 0;
      }
    }
  }
}
//----------------Job Dashboard close-------------------//

//------------Ques-Ans list modal--------------------//
.question_ans_card {
  max-width: 800px;
  margin: 0 0 16px;
  padding: 16px 64px 16px 16px;
  border-radius: 3px;
  background-color: #f6f6f6;
  position: relative;
  @media (max-width: 650px) {
    max-width: 100%;
    padding: 10px;
  }
  &.answer {
    margin: 0 0 16px 49px;
    background: var(--white);
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
    &.tradie_ans {
      background: #f6f6f6;
    }
    @media (max-width: 650px) {
      margin: 0 0 16px 15px;
    }
    @keyframes slide-out {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0%);
      }
    }
    @-webkit-keyframes slide-out {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0%);
      }
    }
  }
  .user_detail {
    display: flex;
    margin-bottom: 15px;
    @media (max-width: 650px) {
      margin-bottom: 10px;
    }
    .user_img {
      width: 40px;
      height: 40px;
      margin: 0 8px 0 0;
      overflow: hidden;
      border-radius: 4px;
      @media (max-width: 650px) {
        width: 30px;
        height: 30px;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .details {
      width: 80%;
      .user_name {
        font: 14px/1.29 "Neue Haas Grotesk Text Pro Medium";
        color: var(--black-two);
        display: block;
      }
      .date {
        font-size: 14px;
        line-height: 1.29;
        color: #aeaeae;
        @media (max-width: 650px) {
          font-size: 12px;
        }
      }
    }
  }
  p {
    margin: 16px 0;
    font-size: 14px;
    line-height: 1.29;
    color: var(--black-two);
    @media (max-width: 650px) {
      margin: 0 0 10px;
    }
  }
  .show_hide_ans,
  .action {
    cursor: pointer;
    display: inline-block;
    font: 14px/1.29 "Neue Haas Grotesk Text Pro Medium";
    color: var(--blue-two);
    margin-right: 16px;
  }
}
//------------Ques-Ans list modal close--------------------//

div#light-box div {
  background-color: #00000073 !important;
  z-index: "9999" !important;
  height: "10%" !important;
  width: "100%" !important;
}
.padding_0 {
  padding: 0px;
  .flex_col_sm_6 {
    width: 66.666667%;
  }
}

.infinite-scroll-component__outerdiv {
  padding-left: 20px;
  width: 100%;
}

//---------------------- Job Dashboard -------------------------//

//----------Revenue-----------//
.flex_row.center_flex {
  .flex_col_sm_2,
  .flex_col_sm_4 {
    @media (max-width: 650px) {
      width: 50%;
      margin-bottom: 10px;
    }
  }
}

.total_count_card {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  li {
    display: inline-block;
    width: 100%;
    max-width: 628px;
    min-height: 134px;
    padding: 33px 15px;
    background-color: #f7f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    @media (max-width: 650px) {
      min-height: 110px;
      padding: 10px 15px;
    }
    &:not(:last-child) {
      margin: 0 32px 0 0;
      @media (max-width: 650px) {
        margin: 0 20px 0 0;
      }
    }
    &:hover {
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }
    .sub_title {
      margin: 0;
    }
    .title {
      margin: 3px 0 0;
    }
    &.revenue {
      max-width: 488px;
      @media (max-width: 768px) {
        max-width: 47%;
      }
      @media (max-width: 650px) {
        max-width: 45%;
      }
    }
    &.job {
      max-width: 384px;
      @media (max-width: 768px) {
        max-width: 47%;
      }
    }
  }
}

.payment_check {
  max-width: 670px;
  .f_spacebw {
    align-items: flex-end;
  }
  .xs_sub_title,
  .info {
    margin-bottom: 0 !important;
  }
}

.last_jobs {
  margin: 32px 0 15px;
  .center_flex {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  .inner_title {
    margin: 0;
  }
}

.img_txt_wrap {
  display: flex;
  align-items: center;
  .job_img {
    width: 56px;
    height: 56px;
    margin: 0 16px 0 0;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #f7f8fa;
    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .details {
    cursor: pointer;
    width: 70%;
    @media (max-width: 768px) {
      width: 66%;
    }
    .inner_title,
    .xs_head {
      margin: 0;
    }
  }
}

.search_bar {
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
  input {
    border-color: transparent !important;
    position: relative;
    padding: 12px 10px 12px 35px;
    width: 0;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    @media (max-width: 650px) {
      padding: 8px 10px 8px 35px;
    }
  }
  .detect_icon_ltr {
    cursor: pointer;
  }
  &.active {
    input {
      background-color: #f7f8fa;
      opacity: 1;
      visibility: visible;
      width: 100%;
      box-shadow: none;
    }
    .detect_icon_ltr {
      z-index: 9;
    }
  }
}

.revenue_detail {
  position: relative;
  .back {
    top: 0;
    transform: initial;
  }
  .total_count_card {
    padding-left: 45px;
    li {
      align-items: initial;
    }
  }
  .edit_icon {
    right: 20px;
  }
}

//---------Revenue close------------//

//----------Chat-------------//
.chat_wrapr {
  height: 100%;
  .side_nav_col {
    width: 100%;
    max-width: 490px;
    @media (max-width: 768px) {
      width: 0;
    }
    &.active {
      @media (max-width: 768px) {
        width: 100%;
        max-width: 550px;
      }
      @media (max-width: 650px) {
        width: 100%;
        max-width: 360px;
      }
    }
  }
  .u_img {
    width: 40px;
    height: 40px;
    margin: 0 12px 0 0;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .search_bar {
    @include position(absolute, 45px, 15px, auto, auto);
    overflow: initial;
    @media (max-width: 768px) {
      top: 22px;
      right: 85px;
    }
    @media (max-width: 650px) {
      top: 5px;
      right: 45px;
    }
    &.active {
      input {
        background-color: var(--white);
      }
      .detect_icon_ltr {
        img {
          width: 22px;
          height: 22px;
        }
      }
    }
    .detect_icon_ltr {
      img {
        width: 42px;
        height: 42px;
        @media (max-width: 650px) {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .detail_col {
    // margin-left: 390px;
    padding: 48px 0px 0 20px;
    overflow: hidden;
    // @media (max-width: 1366px) {
    //   margin-left: 350px;
    // }
    // @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    //   margin-left: 350px;
    // }
    .chat_col {
      width: 100%;
      position: relative;
      padding: 0px 10px 20px 20px;
      height: calc(100vh - 150px);
      transition: all ease-in 0.3s;
      @media (max-width: 1366px) {
        width: 95%;
      }
      &.active {
        width: 55%;
        @media (max-width: 768px) {
          width: 100%;
          padding: 5px 0;
        }
        ~ .view_detail_col {
          transform: translateX(0);
        }
      }
      .chat_user {
        display: flex;
        align-items: center;
        position: relative;
        .u_img {
          width: 40px;
          height: 40px;
          margin: 0 16px 0 0;
          border-radius: 50%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .name {
          font: 20px "Neue Haas Grotesk Text Pro Medium";
          display: block;
          width: 74%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
          @media (max-width: 650px) {
            width: 48%;
          }
        }
        .view_detail {
          @include position(absolute, auto, 0px, auto, auto);
          display: flex;
          align-items: center;
          color: var(--blue-two);
          font: 14px "Neue Haas Grotesk Text Pro Medium";
          cursor: pointer;
          @media (max-width: 650px) {
            top: 10px;
            right: 5px;
            font-size: 11px;
          }
          img {
            margin-left: 10px;
            @media (max-width: 650px) {
              width: 15px;
              height: 15px;
              margin-left: 5px;
            }
          }
        }
      }
      .message_wrapr {
        height: calc(100vh - 270px);
        max-height: 100%;
        overflow-y: auto;
        padding: 30px 10px 60px 2px;
        display: flex;
        align-items: flex-end;
        flex-flow: column;
        // justify-content: flex-end;
        @media (max-width: 1366px) {
          height: 415px;
        }
        @media (max-width: 768px) {
          height: 75%;
        }
        .date_time {
          text-align: center;
          width: 100%;
          span {
            font-size: 14px;
            line-height: 1.29;
            color: #aeaeae;
            display: block;
          }
        }
        .message {
          display: block;
          text-align: right;
          margin: 0 0 24px;
          width: 100%;
          @media (max-width: 1366px) {
            margin: 0 0 18px;
          }
          .time {
            font-size: 12px;
            line-height: 1.33;
            color: #aeaeae;
            @include position(absolute, auto, auto, 0px, -38px);
          }
          .media {
            width: 84px;
            height: 84px;
            border-radius: 12px;
            display: inline-block;
            border: 1px solid var(--grey);
            position: relative;
            overflow: hidden;
            cursor: pointer;
            &.media_video {
              background-color: var(--blue);
              position: relative;
              border-radius: 0px;
              video {
                width: 30px;
                height: 30px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 3px;
            }
          }
          p {
            font-size: 14px;
            line-height: 1.29;
            text-align: left;
            color: var(--greyish-brown);
            max-width: 345px;
            background-color: var(--grey);
            display: inline-block;
            padding: 10px;
            border-radius: 3px;
            position: relative;
            &.mark {
              background-image: linear-gradient(to top, #161d4a, #0b41a8);
              color: #fff;
            }
          }
          &.recive_msg {
            text-align: left;
            p {
              background-color: #dfe5ef;
              .time {
                right: -38px;
                left: auto;
              }
            }
          }
        }
      }
      .send_msg {
        @include position(absolute, auto, auto, 0px, 0px);
        width: calc(100% - 20px);
        background: var(--white);
        box-shadow: 0 -2px 40px 0 rgba(174, 174, 174, 0.2);
        @media (max-width: 1366px) {
          width: 98%;
        }
        @media (max-width: 768px) {
          bottom: 50px;
        }
        input,
        textarea {
          border: none;
          padding: 16px 40px 16px 60px;
          &:focus,
          &:hover {
            border: none !important;
            box-shadow: none !important;
          }
        }
        .hide {
          display: none !important;
        }
        textarea {
          padding: 1px 2px 1px 60px;
          margin: 16px 0 0;
          min-height: auto;
          width: 92%;
          @media (max-width: 768px) {
            width: 88%;
          }
          &::placeholder {
            color: #858e97;
          }
        }
        img {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
    .view_detail_col {
      width: 45%;
      border-left: 1px solid var(--grey);
      padding: 20px;
      transform: translateX(100%);
      @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        position: absolute;
        border: none;
        background-color: var(--white);
      }
      &.active {
        @media (max-width: 768px) {
          right: 0;
        }
      }
      .center_flex {
        margin-bottom: 24px;
        .title {
          margin: 0;
        }
      }
      .close {
        @include position(absolute, 10px, 0px, auto, auto);
        cursor: pointer;
      }
    }
  }
  .tradies_row {
    height: 100%;
  }
}

.chat_list {
  height: calc(100vh - 270px);
  max-height: 100%;
  overflow-y: auto;
  li {
    .chat {
      padding: 19px 10px 19px 120px;
      color: var(--blue);
      display: flex;
      position: relative;
      @media (max-width: 1366px) {
        padding: 15px 10px 17px 120px;
      }
      @media only screen and (min-device-width: 700px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
        padding: 15px 10px 17px 120px;
      }
      @media (max-width: 768px) {
        padding: 15px 10px 17px 20px;
      }
      @media (max-width: 650px) {
        padding: 12px 10px;
      }
      &.active,
      &:hover {
        background: var(--white);
      }
      .u_img {
        width: 64px;
        height: 64px;
      }
      .detail {
        width: 64%;
        .inner_title {
          font-size: 14px;
          margin-bottom: 2px;
          &.job {
            padding-left: 18px;
            position: relative;
            font-family: "Neue Haas Grotesk Text Pro Roman";
            &::before {
              @include position(absolute, 50%, auto, auto, 0px);
              transform: translateY(-50%);
              content: "";
              width: 12px;
              height: 12px;
              background: url("../images/ic-job-fill.png") center no-repeat;
              background-size: contain;
            }
          }
        }
        .commn_para {
          margin: 0;
        }
      }
      .date_time {
        font-size: 14px;
        line-height: 1.43;
        color: var(--dark-grey);
        @include position(absolute, 15px, 15px, auto, auto);
      }
      .count {
        font: 12px/1.67 "Neue Haas Grotesk Text Pro Medium";
        color: var(--greyish-brown);
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--yellow);
        @include position(absolute, auto, 15px, 20px, auto);
        display: flex;
        justify-content: center;
        align-items: center;
        &.grey {
          background-color: var(--dark-grey);
          color: var(--white);
        }
      }
    }
  }
}
//----------Chat close-------------//

//----------Tour Guide-------------//

.h-75vh {
  height: 75vh;
}

.no-scroll {
  overflow: hidden;
}

#react-joyride-step-4,
#react-joyride-step-5,
#react-joyride-step-3 {
  .tour-tooltip {
    margin: 20px 0 0 0px;
    transform: translateX(-70%);
    @media (max-width: 650px) {
      transform: translateX(-48%);
    }
    &-content {
      &::before {
        @include position(absolute, -32px, -65px, auto, auto);
        background: url("../images/arrow-2.png") center no-repeat;
        @media (max-width: 650px) {
          top: -32px;
          right: -25px;
        }
      }
    }
  }
}

#react-joyride-step-4 {
  .tour-tooltip {
    @media (max-width: 768px) {
      transform: translateX(-40%);
    }
  }
}

.tour-tooltip {
  margin: 20px 0 0 250px;
  @media (max-width: 768px) {
    margin: 20px 0 0 270px;
  }
  @media (max-width: 650px) {
    margin: 10px 0 0 80px;
  }
  &-content {
    color: var(--white);
    font-family: "Neue Haas Grotesk Text Pro Roman";
    max-width: 210px;
    margin: 0 0 20px auto;
    font-size: 16px;
    line-height: 1.4;
    text-shadow: 0 0 20px #00000066;
    position: relative;
    &::before {
      @include position(absolute, -32px, auto, auto, -68px);
      content: "";
      width: 68px;
      height: 68px;
      background: url("../images/arrow-1.png") center no-repeat;
      background-size: contain;
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      background-color: transparent;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #fff;
      span {
        margin-left: 5px;
      }
    }
  }
}

//----------Tour Guide close-------------//

//----------M-UI Dialog Title Heading Start-------------//

.xs_alert_dialog_title {
  h2 {
    font: 18px "Neue Haas Grotesk Text Pro Medium";
    color: var(--blue);
    display: block;
    text-align: center;
  }
}
//----------M-UI Dialog Title Heading Close-------------//

//----------Stripe add bank account UI------------//
.StripeElement {
  height: 40px;
  padding: 10px 12px;

  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

//----------Stripe add bank account UI close------------//
