
header {
    position: fixed;
    width: 100%;
    padding: 17px 0;
    -webkit-transition: 0.5s all;
    transition: 0.5s all;
    background-color: var(--blue);
    z-index: 999;
    top: 0;
    left: 0;
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    &.active {
      position: fixed;
      z-index: 9999;
      width: 100%;
      background: var(--white);
      -webkit-transition: 0.5s all;
      transition: 0.5s all;
      box-shadow: 0 0 20px #e7f2ff91;
      transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
   .unreadCountBadge{
    width: 22px;
          height: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          background-color: var(--white);
          position: absolute;
          left: 46px;
          top: -9px;
          color: var(--blue);
          font-family: 'Neue Haas Grotesk Text Pro Roman';
          font-size: 10px;
  }
  .brand_wrap {
    width: 176px;
    height: 34px;
    cursor: pointer;
    @media (max-width: 650px) {
      width: 140px;
    }
  }
  
  .flex_headrow {
    display: flex;
    align-items: center;
  }
  
  .center_nav {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width: 650px) {
      position: fixed;
      width: 95%;
      margin: 0 10px;
      height: 0;
      background: var(--blue);
      left: 0;
      top: 63px;
      flex-flow: column;
      align-items: flex-start;
      justify-content: flex-start;
      box-shadow: 0px 7px 13px 5px #00000036;
      -webkit-transition: all ease 0.2s;
      -o-transition: all ease 0.2s;
      transition: all ease 0.2s;
      display: none;
      &.active {
        height: 25%;
        display: block;
      }
    }
    li {
      display: inline-block;
      margin: 0 15px;
      position: relative;
      @media (max-width: 650px) {
        margin: 10px 0;
        width: 100%;
      }
      a {
        font: 14px/1.43 'Neue Haas Grotesk Text Pro Medium';
        letter-spacing: 0.3px;
        display: block;
        padding: 10px 14px;
        border-radius: 3px;
        color: var(--white);
        &:hover{
          background-color: #fdf076;
          color: var(--blue);
        }
        &.active {
          color: var(--blue);
          background-color: var(--yellow);
          @media (max-width: 650px) {
            background-color: transparent;
            border: transparent !important;
            color: var(--yellow);
          }
        }
        @media (max-width: 650px) {
          font-size: 12px;
          padding: 8px 10px;
        }
      }
    }
  }
  .side_nav {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-flow: column;
    @media (max-width: 650px) {
      width: 75%;
      display: flex;
      flex-flow: row-reverse;
      align-items: center;
      justify-content: end;
      margin-right: 10px;
    }
    li {
      display: inline-block;
      margin: 0 5px;
      a {
        font: 14px/1.43 'Neue Haas Grotesk Text Pro Medium';
        letter-spacing: 0.3px;
        color: var(--white);
        padding: 11px 16px;
        border-radius: 3px;
        &:hover,
        &.active {
          color: var(--black-four);
          background-color: var(--white);
        }
        @media (max-width: 650px) {
          font-size: 12px;
          padding: 8px 10px;
        }
        @media (max-width: 375px) {
          font-size: 11px;
          padding: 6px 9px;
          border-radius: 8px;
        }
      }
      &.mob_nav {
        display: none;
        img {
          margin: 5px 0 0 10px;
          width: 30px;
        }
        @media (max-width: 650px) {
          display: inline-block;
        }
      }
    }
 
    .profile_notification {
      display: flex;
      align-items: center;
      .bell {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: solid 1px rgba(160, 159, 167, 0.35);
        background-color: var(--white);
        position: relative;
        cursor: pointer;
        transition: transform, opacity;
        transition-duration: 0.5s;
        transition-timing-function: ease-out;
        .badge {
          width: 22px;
          height: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          background-color: var(--yellow);
          position: absolute;
          left: -11px;
          top: 0px;
          color: var(--blue);
          font-family: 'Neue Haas Grotesk Text Pro Roman';
          font-size: 10px;
        }
      }
  
      .user_profile {
        margin-left: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        figure {
          width: 40px;
          height: 40px;
          overflow: hidden;
          cursor: pointer;
          border-radius: 50%;
          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
          }
        }
        &.active{
          border-color: var(--yellow);
          
          figure{
            border: none;
          }
        }
      }
    }
  }
  
