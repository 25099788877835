//------------- Position --------------// 
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}
//------------- Position close --------------// 

  
//------------- Border Radius --------------// 
@mixin border-radius($radius){
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -o-border-radius: $radius;
}
 //------------- Border Radius --------------// 


