:root {
  --yellow: #fee600;
  --blue: #161d4a;
  --blue-two: #0b41a8;
  --grey: #dfe5ef;
  --white: #ffffff;
  --black: #000000;
  --black-two: #2d2d2d;
  --black-three: #181818;
  --black-four: #120e24;
  --greyish-brown: #313d48;
  --light-gray: #f0f0f0;
  --dark-grey: #748092;
  --warm-gray: #dfe5ef;
  --red: #c40000;
  --green: #388e3c;
  --info: #25a9e0;
  --warning: #ffc107;
}
